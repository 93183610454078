import { computed, getCurrentInstance, reactive, ref, useAttrs } from "vue";
import _ from "lodash";
import { defineComponent } from "vue";
const __default__ = defineComponent({
  inheritAttrs: false
});
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'index1',
  props: {
    header: {
      type: Boolean,
      default: true
    },
    isSave: {
      type: Boolean,
      default: true
    } // 是否使用 保存 按钮
  },
  emits: ['register', 'on-save', 'on-cancel'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const attrs = useAttrs();
    const visible = ref(false);
    const propsRef = reactive({
      closable: false,
      'mask-closable': false,
      title: undefined,
      width: 400
    });
    const getProps = computed(() => {
      return Object.assign(propsRef, attrs, props);
    });
    const dialogInstance = {
      actionDialog,
      setProps
    };
    function actionDialog(isShow) {
      visible.value = isShow;
    }
    function setProps(propsValue) {
      Object.assign(propsRef || {}, propsValue);
    }

    // 注册
    const instance = getCurrentInstance();
    if (instance) {
      emit("register", dialogInstance);
    }

    /**
     * 保存
     */
    const onSave = _.debounce(() => {
      emit('on-save');
    }, 2000, {
      leading: true,
      trailing: false
    });
    const onCancel = () => {
      emit('on-cancel');
    };
    __expose({
      visible
    });
    const __returned__ = {
      props,
      emit,
      attrs,
      visible,
      propsRef,
      getProps,
      dialogInstance,
      actionDialog,
      setProps,
      instance,
      onSave,
      onCancel,
      defineComponent,
      computed,
      getCurrentInstance,
      reactive,
      ref,
      useAttrs,
      get _() {
        return _;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});