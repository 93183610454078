import { useUserStore } from "@/storePinia/user";
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";
import { mapState, mapActions } from 'vuex';
const __default__ = {
  name: 'iHeaderUser',
  computed: {
    ...mapState('admin/user', ['info']),
    ...mapState('admin/layout', ['isMobile', 'logoutConfirm'])
  },
  methods: {
    ...mapActions('admin/account', ['logout'])
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props, {
    expose: __expose
  }) {
    __expose();
    const router = useRouter();
    const route = useRoute();
    const userStore = useUserStore();
    const handleClick = name => {
      if (name === 'logout') {
        // this.logout({
        //   confirm: this.logoutConfirm,
        //   vm: this
        // });
        userStore.logout();
        router.push(`/login?redirect=${route.fullPath}`);
      }
    };
    const infoAvatar = computed(() => {
      return userStore.userInfo.FAvatar;
    });
    const infoName = computed(() => {
      return userStore.userInfo.FName || userStore.userInfo.FLoginName;
    });
    const __returned__ = {
      router,
      route,
      userStore,
      handleClick,
      infoAvatar,
      infoName,
      get mapState() {
        return mapState;
      },
      get mapActions() {
        return mapActions;
      },
      get useUserStore() {
        return useUserStore;
      },
      get useRoute() {
        return useRoute;
      },
      get useRouter() {
        return useRouter;
      },
      computed
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});