import { computed, reactive, ref, useAttrs } from "vue";
import util from "@/libs/util";
import { Message } from "view-ui-plus";
import Setting from "@/setting";
import { getToken } from "@/utils/auth";
export default {
  __name: 'index',
  props: {
    // header: {type: Boolean, default: true},
  },
  setup(__props, {
    expose: __expose
  }) {
    __expose();
    const token = ref(getToken() || '');
    const upload = ref(null);
    const attrs = useAttrs();
    const propsRef = reactive({
      'max-size': 5120,
      type: 'drag',
      headers: {
        'Authorization': 'Bearer ' + token.value
      },
      name: 'formFile',
      'on-exceeded-size': (file, fileList) => {
        Message.warning(`${file.name}文件大小超出5M，请检查`);
      },
      'action': '',
      actionUrl: `${Setting.request.apiBaseURL}/PLM/Common/UploadFile?obsDir=`
    });
    const props = __props;
    const getProps = computed(() => {
      propsRef.action = propsRef.actionUrl + attrs.obsDir || '';
      return Object.assign(propsRef, attrs, props);
    });

    // defineExpose({
    //   fileList: upload.value
    // });

    const __returned__ = {
      token,
      upload,
      attrs,
      propsRef,
      props,
      getProps,
      computed,
      reactive,
      ref,
      useAttrs,
      get util() {
        return util;
      },
      get Message() {
        return Message;
      },
      get Setting() {
        return Setting;
      },
      get getToken() {
        return getToken;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};