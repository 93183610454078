import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: "btn-group"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Button = _resolveComponent("Button");
  const _component_Drawer = _resolveComponent("Drawer");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_Drawer, _mergeProps({
    modelValue: $setup.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.visible = $event)
  }, $setup.getProps), _createSlots({
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 2 /* DYNAMIC */
  }, [$props.header ? {
    name: "header",
    fn: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_Button, {
      class: "ivu-mr-8",
      onClick: $setup.onCancel
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("取消")])),
      _: 1 /* STABLE */
    }), $props.isSave ? (_openBlock(), _createBlock(_component_Button, {
      key: 0,
      class: "ivu-mr-8",
      type: "primary",
      onClick: $setup.onSave
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("保存")])),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true), _createCommentVNode("                    <Button class=\"ivu-mr-8\" type=\"info\" v-if=\"isCreate == true\" @click=\"onCreate\">创建</Button>")]), _createCommentVNode(" <div class=\"title\">{{title}}</div> ")])]),
    key: "0"
  } : undefined]), 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["modelValue"])]);
}