import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: "btn-group"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Button = _resolveComponent("Button");
  const _component_Drawer = _resolveComponent("Drawer");
  return _openBlock(), _createBlock(_component_Drawer, _mergeProps({
    width: $props.width,
    "mask-closable": false,
    closable: false
  }, _ctx.$attrs, {
    modelValue: $setup.visibleStatus,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.visibleStatus = $event),
    onOnVisibleChange: $setup.onVisibleChange
  }), {
    header: _withCtx(({
      row
    }) => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_Button, {
      class: "ivu-mr-8",
      onClick: $setup.onCancel
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("取消")])),
      _: 1 /* STABLE */
    }), _createVNode(_component_Button, {
      type: "primary",
      onClick: $setup.onSave
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("保存")])),
      _: 1 /* STABLE */
    })])])]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {
      editId: $setup.editId,
      editData: $setup.editData
    })]),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["width", "modelValue"]);
}