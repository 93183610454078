import { isRef, nextTick, onMounted, ref, unref } from "vue";
import { getViewportOffset } from "@/utils/domUtills";
import { isNumber, isString } from "@/utils/is";
import { useWindowSizeFn } from "@/hooks/event/useWindowSizeFn";
export function useContentHeight(flag, anchorRef, subtractHeightRefs, substractSpaceRefs, offsetHeightRef = 0, upwardSpace = ref(0)) {
  // 内容高度
  const contentHeight = ref(null);
  let compensationHeight = {
    useLayoutFooter: true
  };

  // 设置补偿
  const setCompensation = params => {
    compensationHeight = params;
  };

  // 重做高度
  function redoHeight() {
    nextTick(() => {
      calcContentHeight();
    });
  }

  // 计算减法空间
  function calcSubtractSpace(element, direction = 'all') {
    function numberPx(px) {
      return Number(px.replace(/[^\d]/g, ''));
    }
    let subtractHeight = 0;
    const ZERO_PX = '0px';
    if (element) {
      const cssStyle = getComputedStyle(element);
      const marginTop = numberPx(cssStyle.marginTop || ZERO_PX);
      const marginBottom = numberPx(cssStyle.marginBottom || ZERO_PX);
      const paddingTop = numberPx(cssStyle.paddingTop || ZERO_PX);
      const paddingBottom = numberPx(cssStyle.paddingBottom || ZERO_PX);
      if (direction === 'all') {
        subtractHeight += marginTop;
        subtractHeight += marginBottom;
        subtractHeight += paddingTop;
        subtractHeight += paddingBottom;
      } else if (direction === 'top') {
        subtractHeight += marginTop;
        subtractHeight += paddingTop;
      } else {
        subtractHeight += marginBottom;
        subtractHeight += paddingBottom;
      }
    }
    return subtractHeight;
  }
  function getEl(element) {
    if (element == null) {
      return null;
    }
    return element instanceof HTMLDivElement ? element : element.$el;
  }
  // 计算内容高度
  async function calcContentHeight() {
    if (!flag.value) {
      return;
    }
    // 添加延迟以获得正确的高度
    await nextTick();
    // 锚点
    const anchorEl = getEl(unref(anchorRef));
    if (!anchorEl) {
      return;
    }

    // 获取视口偏移量
    const {
      bottomIncludeBody
    } = getViewportOffset(anchorEl);

    // console.log(bottomIncludeBody)

    // 减去元素高度
    let substractHeight = 1;
    if (subtractHeightRefs && subtractHeightRefs[0]) {
      console.log(subtractHeightRefs);
      subtractHeightRefs.forEach(item => {
        var _getEl;
        substractHeight += ((_getEl = getEl(unref(item))) === null || _getEl === void 0 ? void 0 : _getEl.offsetHeight) || 0;
      });
    }

    // console.log(substractHeight)

    // 减去 内/外 边距
    let substractSpaceHeight = calcSubtractSpace(anchorEl) || 0;
    substractSpaceRefs.forEach(item => {
      substractSpaceHeight += calcSubtractSpace(getEl(unref(item)));
    });

    // console.log(substractSpaceHeight)

    // upwardSpace 向上空间
    let upwardSpaceHeight = 0;
    // 向上
    function upward(element, upwardLvlOrClass) {
      if (element && upwardLvlOrClass) {
        const parent = element.parentElement;
        if (parent) {
          if (isString(upwardLvlOrClass)) {
            if (!parent.classList.contains(upwardLvlOrClass)) {
              upwardSpaceHeight += calcSubtractSpace(parent, 'bottom');
              upward(parent, upwardLvlOrClass);
            } else {
              upwardSpaceHeight += calcSubtractSpace(parent, 'bottom');
            }
          } else if (isNumber(upwardLvlOrClass)) {
            if (upwardLvlOrClass > 0) {
              upwardSpaceHeight += calcSubtractSpace(parent, 'bottom');
              upward(parent, --upwardLvlOrClass);
            }
          }
        }
      }
    }
    if (isRef(upwardSpace)) {
      upward(anchorEl, unref(upwardSpace));
    } else {
      upward(anchorEl, upwardSpace);
    }
    if (window.__POWERED_BY_QIANKUN__) {
      contentHeight.value = bottomIncludeBody;
      return;
    }
    let height = bottomIncludeBody - unref(offsetHeightRef) - substractHeight - substractSpaceHeight - upwardSpaceHeight;

    // compensation height 补偿高度

    // 计算补偿高度
    // const calcCompensationHeight = () => {
    //     compensationHeight.elements.forEach((item) => {
    //         height += getEl(unref(item)).offsetHeight || 0;
    //     });
    // };
    // if (compensationHeight.useLayoutFooter) {
    //     calcCompensationHeight();
    // } else {
    //     calcCompensationHeight();
    // }

    contentHeight.value = height;

    // console.log(contentHeight.value)
  }
  onMounted(() => {
    nextTick(() => {
      calcContentHeight();
    });
  });
  useWindowSizeFn(() => {
    calcContentHeight();
  }, 50, {
    immediate: true
  });
  return {
    redoHeight,
    setCompensation,
    contentHeight
  };
}