import "ant-design-vue/es/checkbox/style";
import _checkbox from "ant-design-vue/es/checkbox";
import { Get, Post } from "@api/http";
import { computed, onMounted, reactive, ref, useAttrs, watch } from "vue";
// 下拉框渲染节点，必须

export default {
  __name: 'Select-search-cus',
  props: {
    fieldNames: {
      // 自定义节点 label、value、options 的字段
      type: Object,
      default: () => {
        return {
          value: 'FNumber',
          label: 'FName'
        };
      }
    },
    url: {
      type: String,
      default: () => '/MDM/K3CloudBaseData/Customer/ByName/'
    },
    // 请求的接口地址
    searchFieldName: {
      type: String,
      default: () => 'FChineseValue'
    },
    // 远程收缩的字段名
    // defaultsFirst: {type: String, default: () => ''}
    mode: '' // 选择模式
  },
  emits: ['update:value', 'update:label'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const VNodes = (_, {
      attrs
    }) => {
      return attrs.vnodes;
    };
    const attrs = useAttrs();
    const tableList = ref([]);
    const loading = ref(false);
    const value = ref('');
    const lable = ref('');
    const getList = _.debounce(async (val = '') => {
      try {
        if (val === '' || val === null || val === undefined || val.length == 0) {
          return;
        }
        loading.value = true;
        let Data = await Get(props.url + val);
        tableList.value = Data;
        if (Array.isArray(value.value)) {
          tableList.value = tableList.value.map(e => {
            e.checked = false;
            return e;
          });
          tableList.value = tableList.value.filter(e => {
            return !value.value.includes(e.FName);
          });
          for (let i = value.value.length - 1; i >= 0; i--) {
            tableList.value.unshift({
              FName: value.value[i],
              checked: true
            });
          }

          // 根据已选数量和总数比较，判断全选框是否全选
          checkedAll.value = tableList.value.filter(e => e.checked).length == tableList.value.length;
        }
        loading.value = false;
      } catch (e) {
        console.log(e);
        loading.value = false;
      }
    }, 300);
    const propsRef = reactive({
      'show-search': true,
      defaultActiveFirstOption: false,
      showArrow: false,
      filterOption: false,
      'not-found-content': null,
      allowClear: true
    });
    const props = __props;
    const emit = __emit;
    watch(() => value.value, n => {
      emit("update:value", value.value);
      emit("update:label", lable.value);
    });
    const handleChange = (value, option) => {
      if (!value) {
        getList();
      } else {
        if (props.url === '/PLM/Approve/SummaryPageList') {
          lable.value = option.FSpuNameZh;
        } else {
          lable.value = option[props.fieldNames.label];
        }
      }
      if (Array.isArray(value)) {
        tableList.value.forEach(element => {
          element.checked = value.includes(element.name);
        });
      }
      checkedAll.value = tableList.value.filter(e => e.checked).length == tableList.value.length;
    };
    const getProps = computed(() => {
      return Object.assign(propsRef, attrs, props);
    });
    onMounted(() => {
      if (props.mode == 'multiple') {
        value.value = [];
      }
    });

    // 全选
    const checkedAll = ref(false);
    const btnAll = () => {
      checkedAll.value = !checkedAll.value;
      tableList.value = tableList.value.map(e => {
        e.checked = checkedAll.value;
        if (checkedAll.value) {
          value.value.push(e.FNumber);
        } else {
          value.value = [];
        }
        return e;
      });
      // 去重
      value.value = [...new Set(value.value)];
    };
    __expose({
      getList
    });
    const __returned__ = {
      VNodes,
      attrs,
      tableList,
      loading,
      value,
      lable,
      getList,
      propsRef,
      props,
      emit,
      handleChange,
      getProps,
      checkedAll,
      btnAll,
      get Get() {
        return Get;
      },
      get Post() {
        return Post;
      },
      computed,
      onMounted,
      reactive,
      ref,
      useAttrs,
      watch,
      get aCheckbox() {
        return _checkbox;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};