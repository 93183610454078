import { useCssVars as _useCssVars, unref as _unref } from 'vue';
import { ref, toRefs, reactive, onMounted, computed, nextTick, watch, onActivated, onDeactivated } from 'vue';
import { Get, Post } from "@/api/http.js";
import { useTableScroll } from "./hooks/useTableScroll";
import SearchForm from "@/components/Form/src/SearchForm.vue";

// 全局 ref

import { useTableTool } from "@/core/CommonHooks/useTableTool";
import { Modal, Table } from "view-ui-plus";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";
import dayjs from "dayjs";
const __default__ = {
  name: 'base-table'
};
export default /*@__PURE__*/Object.assign(__default__, {
  props: {
    filter: {
      type: Array,
      default: () => {
        return [];
      }
    },
    defaultFilter: {
      type: Array,
      default: () => {
        return [];
      }
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      }
    },
    url: {
      type: String,
      required: true
    },
    PrimaryKey: {
      type: String,
      default: "FId"
    },
    orders: {
      type: Array,
      default: () => {
        return [];
      }
    },
    pageSize: {
      type: Number,
      default: 1
    },
    pageIndex: {
      type: Number,
      default: 20
    },
    interfaceParams: {
      type: Array,
      default: () => []
    },
    // 额外接口参数，改变即触发getList
    leftBoxWidth: {
      type: String,
      default: '300px'
    },
    // 左边节点宽度
    notHaveHeaderBtn: {
      type: Boolean,
      default: false
    },
    // 没有头部操作按钮（用来减去table自适应高度）
    notHaveHeaderTab: {
      type: Boolean,
      default: true
    },
    // 没有头部tab（用来减去table自适应高度）
    recordQueryKey: {
      type: String,
      default: 'FId'
    },
    // 操作记录查询接口参数的key
    listFilterFun: {
      type: Function,
      default: () => undefined
    },
    // 处理table-data的方法
    showSummary: {
      type: Boolean,
      default: false
    },
    // 设置属性 show-sumTransmisException 为 true 会在表格尾部展示合计行
    summaryMethod: {
      type: Function,
      default: () => undefined
    },
    // 自定义合计行每列显示的内容
    mergeArr: {
      type: Array,
      default: () => []
    },
    // 单元格合并的key集合
    spanMethodStatus: {
      type: Boolean,
      default: false
    },
    // 开启单元格合并
    pageShow: {
      type: Boolean,
      default: true
    },
    // 是否显示分页
    rowClassName: {
      type: Function,
      default: () => undefined
    },
    // 行的 className 的回调方法，传入参数：row：当前行数据 index：当前行的索引
    // activeKey:{type:[String,Number],default:''},   // 开发进度页面tab选项
    clearActiveKey: {
      type: [String, Number],
      default: ''
    }
  },
  emits: ['subClick', 'getListSuccess'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    _useCssVars(_ctx => ({
      "4e02012f-leftBoxWidth": _unref(leftBoxWidth)
    }));
    const emits = __emit;
    const route = useRoute();
    const router = useRouter();
    const props = __props;
    const {
      url,
      orders,
      PrimaryKey,
      defaultFilter,
      leftBoxWidth
    } = toRefs(props);
    watch(() => props.interfaceParams, nV => {
      getList();
    });
    watch(() => props.clearActiveKey, nV => {
      searchFourTime.value = [];
    });
    const wrapRef = ref(null);
    const formRef = ref();
    const refTable = ref(null);
    const {
      calcHeight,
      redoHeight
    } = useTableScroll(refTable, wrapRef, formRef);
    const {
      sysTableToolGetSelectedRowIndex,
      sysTableToolGetSelectedRow,
      sysTableToolSelectAll
    } = useTableTool(refTable);

    // watch(calcHeight, () => {
    //   let dom = document.getElementsByClassName('ivu-table-body')
    //   if (dom.length > 0) {
    //     console.log(dom[0].offsetHeight)
    //     let height = dom[0].offsetHeight
    //     dom[0].style.height = height - 80
    //   }
    //   console.log(dom[0].offsetHeight)
    //   console.log('触发了')
    //
    // }, {
    //   immediate: true
    // })

    /**
     * 快捷搜索
     */
    const filterRuleData = ref([]);
    const onFilterChange = (data, type) => {
      searchFourTime.value = [];
      filterRuleData.value = data || [];
      listPageIndex.value = 1;
      // if(!type) {
      //   getList();
      // }
      getList();
    };
    const onFold = () => {
      redoHeight();
    };

    /**
     * 列表排序处理
     */
    const orderRuleData = ref(orders.value);
    const mergeObj = ref({});
    const getSpanArr = data => {
      props.mergeArr.forEach((key, index1) => {
        let count = 0; // 用来记录需要合并行的起始位置
        mergeObj.value[key] = []; // 记录每一列的合并信息
        data.forEach((item, index) => {
          // index == 0表示数据为第一行，直接 push 一个 1
          if (index === 0) {
            mergeObj.value[key].push(1);
          } else {
            // 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
            if (item[key] === data[index - 1][key]) {
              mergeObj.value[key][count] += 1;
              mergeObj.value[key].push(0);
            } else {
              // 如果当前行和上一行其值不相等
              count = index; // 记录当前位置
              mergeObj.value[key].push(1); // 重新push 一个 1
            }
          }
        });
      });
      console.log(mergeObj.value);
    };
    const objectSpanMethod = ({
      row,
      column,
      rowIndex,
      columnIndex
    }) => {
      // 判断列的属性
      if (props.mergeArr.indexOf(column.key) !== -1) {
        // 判断其值是不是为0
        if (mergeObj.value[column.key][rowIndex]) {
          return [mergeObj.value[column.key][rowIndex], 1];
        } else {
          // 如果为0则为需要合并的行
          return [0, 0];
        }
      }
    };

    /**
     * 列表数据处理
     */
    const tableData = ref([]);
    const cache = ref(true); // 无感刷新

    const tableLoadingStatus = ref(false);
    const paramsForm = ref({});
    const interfaceRes = ref({});
    const searchFourTime = ref([]);
    const getList = (cacheType = true) => {
      tableLoadingStatus.value = cacheType;
      let data = {
        "FPageSize": listPageSize.value,
        "FPageIndex": listPageIndex.value,
        "FOrders": orderRuleData.value,
        "FRules": [...[...defaultFilter.value, ...filterRuleData.value, ...props.interfaceParams], ...searchFourTime.value]
      };
      emits('getListSuccess', data);
      Post(`${url.value}`, data).then(res => {
        paramsForm.value = data;
        interfaceRes.value = res;
        listPageIndex.value = res === null || res === void 0 ? void 0 : res.PageIndex;
        listPageSize.value = res === null || res === void 0 ? void 0 : res.PageSize;
        listPageTotal.value = res === null || res === void 0 ? void 0 : res.TotalCount;
        emits('subClick', res.planTotalInfo ? res.planTotalInfo.allCount : 0);
        if (res !== null && res !== void 0 && res.Items) {
          if (props !== null && props !== void 0 && props.listFilterFun(res.Items)) {
            let arr = props === null || props === void 0 ? void 0 : props.listFilterFun(res.Items);
            props.spanMethodStatus && getSpanArr(arr);
            console.log('是这里吧');
            console.log(arr);
            tableData.value = arr;
          } else {
            props.spanMethodStatus && getSpanArr(res.Items);
            tableData.value = res.Items || [];
          }
          console.log('这里吗？');
          console.log(tableData.value);
        } else {
          tableData.value = [];
        }

        // 库存监控页面 数据结构单独处理
        if (url.value === '/Mon/Inventory/PageList' || url.value === '/Mon/Deliver/PageList' || url.value === '/Mon/Deliver/CDeliverPageList' || url.value === '/Mon/Deliver/EORTOrderPageList') {
          let {
            pageData: {
              PageIndex,
              PageSize,
              TotalCount,
              Items
            }
          } = res;
          listPageIndex.value = PageIndex;
          listPageSize.value = PageSize;
          listPageTotal.value = TotalCount;

          // 店铺订单传输马帮监控 单独处理合并单元格唯一值
          if (url.value === '/Mon/Deliver/EORTOrderPageList') {
            Items.forEach((v, index) => {
              Items[index]._id = `${Items[index].FEOrderId}${Items[index].FMBOrderId}`;
            });
          }
          if (url.value === '/Mon/Deliver/PageList') {
            Items.forEach((v, index) => {
              Items[index]._id = `${Items[index].FMBOrderId}${Items[index].FWMSOrderId}`;
            });
          }
          props.spanMethodStatus && getSpanArr(Items);
          tableData.value = Items || [];
        }

        // 订单监控页面 数据结构单独处理
        if (url.value === '/SAL/PlatformBusinessData/MonitorOrder/PageList') {
          let {
            Result: {
              PageIndex,
              PageSize,
              TotalCount,
              Items
            }
          } = res;
          listPageIndex.value = PageIndex;
          listPageSize.value = PageSize;
          listPageTotal.value = TotalCount;

          // // 店铺订单传输马帮监控 单独处理合并单元格唯一值
          // if (url.value === '/Mon/Deliver/EORTOrderPageList') {
          //   Items.forEach((v, index) => {
          //     Items[index]._id = `${Items[index].FEOrderId}${Items[index].FMBOrderId}`
          //   })
          // }
          props.spanMethodStatus && getSpanArr(Items);
          tableData.value = Items || [];
        }

        // 马帮金蝶传输
        if (url.value === '/FIN/StockOutBill/StockOutBillMonitor') {
          if (listPageSize.value == "") {
            listPageSize.value = 20;
          }
        }

        // 开发计划页面 数据结构单独处理
        if (url.value === '/PLM/PlanAppService/GetPageList') {
          if (props !== null && props !== void 0 && props.listFilterFun(res)) {
            let arr = props === null || props === void 0 ? void 0 : props.listFilterFun(res);
            props.spanMethodStatus && getSpanArr(arr);
            tableData.value = arr;
          } else {
            props.spanMethodStatus && getSpanArr(res);
            tableData.value = res || [];
          }
        }
        if (url.value === '/PLM/PlanAppService/GetDevelopmentSchedule') {
          console.log(res);
          let {
            Items
          } = res;
          Items.forEach((item, i) => {
            if (item.planSpuId) {
              // if(item.FPlanStatus){
              console.log("==========================");
              console.log(item);
              console.log("立项");
            } else {
              console.log("未立项");
            }
          });
        }

        // document.getElementsByClassName('ivu-table-body')?.[0]?.scrollTop = 0
        tableLoadingStatus.value = false;
        cache.value = false;
      });
    };
    const getFourSearch = () => {
      formRef.value.handleReset(null, true);
      listPageIndex.value = 1;
      // filterRuleData.value = []
      // searchFourTime.value = [{Key: "FDate", LogicalOperator: "and", Operator: "GreaterEqual", value: dayjs().add(-4, 'month').format('YYYY-MM-DD HH:mm:ss')},
      //     {Key: "FDate", LogicalOperator: "and", Operator: "LessEqual", value: dayjs().format('YYYY-MM-DD HH:mm:ss')}]
      searchFourTime.value = [{
        Key: "FDate",
        LogicalOperator: "and",
        Operator: "GreaterEqual",
        value: dayjs().format('YYYY-MM-DD HH:mm:ss')
      }, {
        Key: "FDate",
        LogicalOperator: "and",
        Operator: "LessEqual",
        value: dayjs().add(+4, 'month').format('YYYY-MM-DD HH:mm:ss')
      }];
      getList();
    };

    /**
     * 分页处理
     */
    const listPageIndex = ref(1);
    const listPageSize = ref(20);
    const listPageTotal = ref(0);

    // 当前页发生改变
    const onPageChange = (data = 1) => {
      listPageIndex.value = data;
      getList();
    };
    const onPageSizeChange = data => {
      // pagesize 发生改变
      listPageIndex.value = 1;
      listPageSize.value = data;
      getList();
    };

    /**
     * 数据编辑处理
     */
    const refDrawerEdit = ref();
    const onRowEditClick = (data, index, row) => {
      refDrawerEdit.value.visibleStatus = true;
      refDrawerEdit.value.editId = row[PrimaryKey.value];
    };

    // 点击操作记录
    const onHistory = (row, queryKey) => {
      Get('/PLM/Common/GetOperatorLog', {
        id: row[queryKey]
      }).then(res => {
        let columns = [{
          title: '操作人',
          width: 120,
          key: 'FOperatorName'
        }, {
          title: '操作时间',
          width: 170,
          key: 'FOperatorTime'
        }, {
          title: '操作内容',
          key: 'FDescription'
        }];
        Modal.info({
          width: 800,
          title: '操作记录',
          closable: true,
          scrollable: true,
          render: h => {
            return h(Table, {
              columns: columns,
              data: res,
              size: 'small',
              border: true,
              height: 400
            });
          }
        });
      });
    };
    const calcTableHeight = computed(() => {
      let num = _.cloneDeep(calcHeight.value);
      if (props.notHaveHeaderBtn) {
        num = num + 56;
      }
      if (!props.notHaveHeaderTab) {
        num = num - 56;
      }
      return num;
    });

    /**
     * 生命周期事件
     */
    onMounted(() => {
      if (!route.meta.cache) {
        getList();
      }
    });
    onActivated(() => {
      if (route.meta.cache) {
        getList(cache.value);
      }
    });

    // watch(() => document.getElementsByClassName('ivu-table-body')[0].scrollTop, (nv) => {
    //   console.log(nv)
    // }, {
    //   deep: true
    // })

    onDeactivated(() => {
      // 记录滚动条位置
      // let scrollTop =  document.getElementsByClassName('ivu-table-body')[0].scrollTop;
      // console.log(document.getElementsByClassName('ivu-table-body'))
      // console.log('走了走了' + scrollTop)
    });
    __expose({
      refDrawerEdit,
      sysTableToolGetSelectedRowIndex,
      getList,
      sysTableToolGetSelectedRow,
      sysTableToolSelectAll,
      tableData,
      onPageChange,
      interfaceRes,
      paramsForm,
      getFourSearch
    });
    const __returned__ = {
      emits,
      route,
      router,
      props,
      url,
      orders,
      PrimaryKey,
      defaultFilter,
      leftBoxWidth,
      wrapRef,
      formRef,
      refTable,
      calcHeight,
      redoHeight,
      sysTableToolGetSelectedRowIndex,
      sysTableToolGetSelectedRow,
      sysTableToolSelectAll,
      filterRuleData,
      onFilterChange,
      onFold,
      orderRuleData,
      mergeObj,
      getSpanArr,
      objectSpanMethod,
      tableData,
      cache,
      tableLoadingStatus,
      paramsForm,
      interfaceRes,
      searchFourTime,
      getList,
      getFourSearch,
      listPageIndex,
      listPageSize,
      listPageTotal,
      onPageChange,
      onPageSizeChange,
      refDrawerEdit,
      onRowEditClick,
      onHistory,
      calcTableHeight,
      ref,
      toRefs,
      reactive,
      onMounted,
      computed,
      nextTick,
      watch,
      onActivated,
      onDeactivated,
      get Get() {
        return Get;
      },
      get Post() {
        return Post;
      },
      get useTableScroll() {
        return useTableScroll;
      },
      SearchForm,
      get useTableTool() {
        return useTableTool;
      },
      get Modal() {
        return Modal;
      },
      get Table() {
        return Table;
      },
      get useRoute() {
        return useRoute;
      },
      get useRouter() {
        return useRouter;
      },
      get _() {
        return _;
      },
      get dayjs() {
        return dayjs;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});