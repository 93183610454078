import { onActivated, onMounted, ref, watch, computed } from "vue";
import { Get, Post } from "@api/http";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import SelectDevPeople from '@/components/Select/src/Select-devPeople.vue';
import SelectCustom from '@/components/Select/src/Select-custom.vue';
import SelectCustomCheckBox from '@/components/Select/src/Select-custom-checkBox.vue';
import SelectCustomCheckBoxFid from '@/components/Select/src/Select-custom-checkBoxFid.vue';
import SelectCustomCheckBoxNo from '@/components/Select/src/Select-custom-checkBoxNo.vue';
import InputIcon from '@/components/Select/src/InputIcon.vue';
import SelectDeveloper from "@/components/Select/src/Select-developer.vue";
import SelectSearchCus from '@/components/Select/src/Select-search-cus.vue';
import _ from "lodash";
import { Checkbox } from "view-ui-plus";
export default {
  __name: 'SearchForm',
  props: {
    fields: {
      type: Array,
      default: () => {
        return [];
      }
    },
    activeKey: {
      type: [String, Number],
      default: ''
    }
  },
  emits: ['on-search', 'on-fold'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const props = __props;
    const fromValue = ref({});
    const formInline = ref();
    const time = ref([]);
    const foldButtonShow = ref(false);
    const foldStatus = ref(false);
    const pickerOptions = ref({
      shortcuts: [{
        text: '近3天',
        value() {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
          return [start, end];
        }
      }, {
        text: '近7天',
        value() {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
          return [start, end];
        }
      }, {
        text: '近30天',
        value() {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
          return [start, end];
        }
      }, {
        text: '近3个月',
        value() {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 89);
          return [start, end];
        }
      }]
    });
    watch(() => props.activeKey, val => {
      handleSubmit();
      console.log(fromValue.value, 0);
    });
    onMounted(() => {
      props.fields.forEach((v, index) => {
        fromValue.value[v.value] = '';

        // 字典下拉框
        if (v.component === 'dict-select') {
          getPresetData(v, index);
        }
        if (v.component === 'dict-select-value') {
          getPdter(v, index);
        }
        if (v.component === 'date-picker' && !v.dataNo) {
          fromValue.value[v.value] = [dayjs().subtract(7, 'day').format('YYYY-MM-DD HH:mm:ss'), dayjs().format('YYYY-MM-DD HH:mm:ss')];
        }
        if (v.component === 'date-picker-one' && !v.dataNo) {
          fromValue.value[v.value] = [dayjs().startOf("month").format('YYYY-MM-DD HH:mm:ss'), dayjs().format('YYYY-MM-DD HH:mm:ss')];
        }

        // 处理默认值
        if (v.data) {
          fromValue.value[v.value] = v.data;
        }

        // 处理日期
      });
      foldButtonShow.value = formInline.value.$el.offsetHeight > 50;
      foldStatus.value = formInline.value.$el.offsetHeight > 50;

      // 判断有默认值就调用搜索接口
      let flag = false;
      for (const i in fromValue.value) {
        if (fromValue.value[i]) {
          flag = true;
        }
      }

      // 调用收缩接口
      handleSubmit('formInline');
      getDictList();
      getPdter();

      // Get('/PLM/Presets/PresetsDropdownList').then(res=> {
      //   console.log(res)
      // })
    });
    onActivated(() => {
      setTimeout(() => {
        props.fields.forEach((v, index) => {
          console.log('嘎嘎嘎');

          // 处理默认值
          // if (v.data) {
          //   console.log(v.data)
          //   fromValue.value[v.value] = v.data
          // }
        });
      }, 400);
    });
    const dictSelectRef = ref(null);
    const inputRef = ref(null);
    const inputRef1 = ref(null);
    const calcOffsetTop = ref => {
      // console.log('高度来了')
      // console.log(ref.$el.offsetTop)
      // console.log(ref)
      if (ref) {
        let topArr = ref.map(v => v.$el.offsetTop);
        console.log(topArr);
      }
    };

    // 预设数据
    const presetArr = ref([]);
    // 获取预设数据
    const getPresetData = async (item, index) => {
      let {
        componentConfig
      } = item;
      let {
        Items
      } = await Post('/PLM/Presets/ItemList', {
        "FPageSize": 999,
        "FPageIndex": 1,
        "FOrders": [],
        "FRules": [{
          "Key": "FPresetsCode",
          "LogicalOperator": "and",
          "Operator": "Equals",
          "Value": componentConfig.code
        }, {
          "Key": "FStatus",
          "LogicalOperator": "and",
          "Operator": "Equals",
          "Value": 1
        }]
      });
      props.fields[index].presetArr = Items;
    };
    const pdterData = ref([]);
    const getPdter = async () => {
      try {
        let {
          Items
        } = await Post('/PLM/Presets/ItemList', {
          "FPageSize": 9999,
          "FPageIndex": 1,
          "FOrders": [],
          "FRules": [{
            "Key": "FPresetsId",
            "LogicalOperator": "and",
            "Operator": "Equals",
            "Value": "533751745614661"
          }]
        });
        pdterData.value = Items;
        // console.log('数据111',pdterData.value)
      } catch (e) {
        console.log(e);
      }
    };
    const pickerChange = (v, value) => {
      let arr = [];
      arr = v;
      if (arr.includes('')) {
        fromValue.value[value] = undefined;
      }
    };
    const emit = __emit;
    const filterParams = () => {
      let ruleData = [];
      for (const k in fromValue.value) {
        if (fromValue.value[k] || fromValue.value[k] === 0) {
          // 父组件传递过来的item
          let beforeOneItem = props.fields.find(v => v.value === k);
          console.log(k);
          console.log(beforeOneItem);
          console.log(fromValue.value[k]);
          console.log(fromValue.value[k].length);
          console.log();
          // 处理日期
          if (beforeOneItem.component === 'date-picker' || beforeOneItem.component === 'date-picker-one') {
            let flag = false;
            fromValue.value[k].forEach(v => {
              if (['', undefined, null].includes(v)) {
                flag = true;
              }
            });
            if (!flag) {
              beforeOneItem.value.split('&&').forEach((v, index) => {
                ruleData.push({
                  Key: v,
                  LogicalOperator: 'and',
                  Operator: index === 0 ? 'GreaterEqual' : 'LessEqual',
                  Value: index === 0 ? dayjs(fromValue.value[k][index]).format('YYYY-MM-DD HH:mm:ss') : dayjs(fromValue.value[k][index]).format('YYYY-MM-DD') + ' 23:59:59'
                });
              });
            }
          }

          // 年月
          else if (beforeOneItem.component === 'date-picker-month') {
            ruleData.push({
              Key: k,
              LogicalOperator: 'and',
              Operator: beforeOneItem.operator || 'Contains',
              // Value: fromValue.value[k].toLocaleDateString()
              Value: beforeOneItem.format ? dayjs(fromValue.value[k]).format(beforeOneItem.format) : dayjs(fromValue.value[k]).format('YYYY-MM')
            });
          }
          // 年
          else if (beforeOneItem.component === 'date-picker-year') {
            ruleData.push({
              Key: k,
              LogicalOperator: 'and',
              Operator: beforeOneItem.operator || 'Contains',
              Value: dayjs(fromValue.value[k]).format('YYYY')
            });
          } else if (beforeOneItem.component === 'date-picker-month') {
            ruleData.push({
              Key: k,
              LogicalOperator: 'and',
              Operator: beforeOneItem.component === 'select' ? 'Equals' : beforeOneItem.component === 'input-number' ? 'Equals' : 'Contains',
              Value: fromValue.value[k].toLocaleDateString()
            });
          } else if (beforeOneItem.component === 'date-picker-month1') {
            ruleData.push({
              Key: k,
              LogicalOperator: 'and',
              Operator: beforeOneItem.component === 'select' ? 'Equals' : beforeOneItem.component === 'input-number' ? 'Equals' : 'Contains',
              Value: dayjs(fromValue.value[k]).format('YYYY-MM')
            });
          } else if (beforeOneItem.component === 'input-multiple' && beforeOneItem.valueType === 'Array') {
            let arr = fromValue.value[k].split(',');
            arr.forEach(v => {
              ruleData.push({
                Key: beforeOneItem.value,
                LogicalOperator: 'and',
                Operator: 'Equals',
                Value: v
              });
            });
          }
          // else if (beforeOneItem.component === 'select-search-custom' && beforeOneItem.valueType == 'Array') {
          //   // fromValue.value[k].forEach(v => {
          //    if(fromValue.value[k].length > 0) {
          //      ruleData.push({
          //        Key: beforeOneItem.value,
          //        LogicalOperator: 'OR',
          //        Operator: 'Equals',
          //        Value: fromValue.value[k].join(',')
          //      })
          //    }
          // }
          else if ((beforeOneItem.component === 'select-search-custom' || beforeOneItem.component === 'select-search-custom-checkBox' || beforeOneItem.component === 'select-search-custom-checkBoxNo' || beforeOneItem.component === 'select-type' || beforeOneItem.component === 'select-search-cus') && beforeOneItem.valueType == 'Array') {
            if (fromValue.value[k].length > 0) {
              ruleData.push({
                Key: beforeOneItem.value,
                LogicalOperator: 'OR',
                Operator: 'Equals',
                Value: fromValue.value[k].join(',')
              });
            }
          } else if (beforeOneItem.component === 'select-search-custom-checkBoxFid') {
            if (fromValue.value[k].length > 0) {
              ruleData.push({
                Key: beforeOneItem.value,
                LogicalOperator: 'OR',
                Operator: 'Contains',
                Value: fromValue.value[k].join(',')
              });
            }
          } else if (beforeOneItem.component === 'select-multiple' || beforeOneItem.component === 'select-multiple-type') {
            let arr = fromValue.value[k];
            arr.forEach(v => {
              ruleData.push({
                Key: beforeOneItem.value,
                LogicalOperator: 'OR',
                Operator: 'Equals',
                Value: v
              });
            });
          } else {
            let obj = {
              Key: k,
              LogicalOperator: 'and',
              Operator: beforeOneItem.component === 'select' ? 'Equals' : beforeOneItem.component === 'input-number' ? 'Equals' : 'Contains',
              Value: fromValue.value[k]
            };
            if (beforeOneItem.operator) {
              obj.Operator = beforeOneItem.operator;
            }
            ruleData.push(obj);
          }
        }
      }
      // ruleData.push({
      //   Key: 'FPlanStatus',
      //   LogicalOperator: 'and',
      //   Operator: 'Equals',
      //   Value: props.activeKey
      // })

      return ruleData;
    };
    // const handleInput = (event) => {
    //   const inputValue = event.target.value;
    //   fromValue.value = inputValue.replace(/[\r\n]+/g, ',');
    // };
    const listionPaste = (event, value) => {
      event.preventDefault();
      // 获取已选中的字符串
      const selectedText = window.getSelection().toString();

      // 删除已选中的字符串
      document.execCommand("delete");

      // 获取粘贴的内容
      const pasteText = event.clipboardData.getData('text/plain');
      fromValue.value[value] += (fromValue.value[value] != "" ? "," : "") + pasteText.split('\r\n').join(',');
    };
    const handleSubmit = () => {
      emit(`on-search`, filterParams());
    };
    const handFold = () => {
      foldStatus.value = !foldStatus.value;
      emit(`on-fold`, foldStatus.value);
    };
    const handleReset = () => {
      for (const k in fromValue.value) {
        // fromValue.value[k] = ''
        if (Array.isArray(fromValue.value[k])) {
          fromValue.value[k] = [];
        } else {
          fromValue.value[k] = '';
        }

        // 父组件传递过来的item
        let beforeOneItem = props.fields.find(v => v.value === k);

        // 处理日期
        if (beforeOneItem.component === 'date-picker') {
          // 有默认值
          if (beforeOneItem.data) {
            fromValue.value[k] = beforeOneItem.data;
          } else {
            fromValue.value[k] = [dayjs().subtract(7, 'day').format('YYYY-MM-DD HH:mm:ss'), dayjs().format('YYYY-MM-DD HH:mm:ss')];
          }
        }
        if (beforeOneItem.component === 'date-picker-one') {
          // 有默认值
          if (beforeOneItem.data) {
            fromValue.value[k] = beforeOneItem.data;
          } else {
            fromValue.value[k] = [dayjs().startOf("month").format('YYYY-MM-DD HH:mm:ss'), dayjs().format('YYYY-MM-DD HH:mm:ss')];
          }
        }
      }
      emit(`on-reset`, filterParams());
    };
    const custumList = ref([]);
    const getList = _.debounce(async value => {
      try {
        console.log(value);
        if (value === '' || value === null || value === undefined) {
          return;
        }
        // loading.value =true
        let Data = await Get('/MDM/PlatformShop/ByName/' + value);
        custumList.value = Data.map(e => {
          return {
            value: e.name,
            label: e.name
          };
        });
        // loading.value =false
      } catch (e) {
        console.log(e);
        // loading.value =false
      }
    }, 300);
    const OutStockAbnormalEnumList1 = ref({});
    const getDictList = async () => {
      try {
        let res = await Get('/FIN/StockOutBill/OutStockAbnormalEnumList');
        OutStockAbnormalEnumList1.value = res;
        console.log('@res111=======', OutStockAbnormalEnumList1.value);
      } catch (e) {
        console.log(e);
      }
    };
    __expose({
      handleReset
    });
    const __returned__ = {
      props,
      fromValue,
      formInline,
      time,
      foldButtonShow,
      foldStatus,
      pickerOptions,
      dictSelectRef,
      inputRef,
      inputRef1,
      calcOffsetTop,
      presetArr,
      getPresetData,
      pdterData,
      getPdter,
      pickerChange,
      emit,
      filterParams,
      listionPaste,
      handleSubmit,
      handFold,
      handleReset,
      custumList,
      getList,
      OutStockAbnormalEnumList1,
      getDictList,
      onActivated,
      onMounted,
      ref,
      watch,
      computed,
      get Get() {
        return Get;
      },
      get Post() {
        return Post;
      },
      get dayjs() {
        return dayjs;
      },
      get timezone() {
        return timezone;
      },
      get utc() {
        return utc;
      },
      SelectDevPeople,
      SelectCustom,
      SelectCustomCheckBox,
      SelectCustomCheckBoxFid,
      SelectCustomCheckBoxNo,
      InputIcon,
      SelectDeveloper,
      SelectSearchCus,
      get _() {
        return _;
      },
      get Checkbox() {
        return Checkbox;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};