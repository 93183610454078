import { ref, reactive, onMounted, computed, nextTick } from 'vue';
    import { useRoute, useRouter } from 'vue-router'
    import sidebar from "../../menu/sidebar.js";

    
export default {
  __name: 'index',
  setup(__props, { expose: __expose }) {
  __expose();

    const $router = useRouter();
    const refDropdown = ref();

    const selectedClass = ref(0);               // 选中分组 一级
    const selectedClassChildren = ref(0);       // 选中分组 二级
    const selectedClassSpread = ref([0]);       // 展架的分组

    const listData = ref([]);
    
    /**
     * 分组数据计算属性
     */
    const classData = computed(() => {
        let ds = sidebar.map((item, index) => {
            return { "title": item.title, "index": index, "children": item.children };
        })
        return ds;
    });

    /**
     * 分组单击选中
     */
    const onClassClick = (index) => {
        // 展开状态处理
        let isIndex = selectedClassSpread.value.indexOf(index);
        isIndex < 0 ? selectedClassSpread.value.push(index) : selectedClassSpread.value.splice(isIndex, 1);
    };

    const onClassChildrenClick = (index2, index1) => {
        let menus = sidebar[index1].children[index2].children;
        listData.value = menus;

        selectedClass.value = index1;
        selectedClassChildren.value = index2;
    };

    /**
     * 菜单单击选中
     */
    const onClick = (data) => {
        let { path, title } = data;
        $router.push({ path: path });
        refDropdown.value.handleHoverClick();
    }

    // onMounted 生命周期
    onMounted(()=>{
        let menus = sidebar[0].children[0].children;
        listData.value = menus;
    });



const __returned__ = { $router, refDropdown, selectedClass, selectedClassChildren, selectedClassSpread, listData, classData, onClassClick, onClassChildrenClick, onClick, ref, reactive, onMounted, computed, nextTick, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get sidebar() { return sidebar } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

}