import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "custom-option"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_divider = _resolveComponent("a-divider");
  const _component_plus_outlined = _resolveComponent("plus-outlined");
  const _component_a_select = _resolveComponent("a-select");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_a_select, _mergeProps({
    style: {
      "width": "100%"
    },
    value: $setup.value,
    "onUpdate:value": _cache[1] || (_cache[1] = $event => $setup.value = $event),
    onSearch: _cache[2] || (_cache[2] = v => $setup.getList(v)),
    onChange: $setup.handleChange,
    fieldNames: $props.fieldNames
  }, $setup.getProps, {
    options: $setup.tableList,
    maxTagCount: 1
  }), {
    option: _withCtx(option => [_createElementVNode("div", _hoisted_1, [_createCommentVNode(" pointer-events: none; 设置复选框不可点，用于穿透事件 "), _createVNode($setup["aCheckbox"], {
      style: {
        "pointer-events": "none"
      },
      checked: option.checked,
      "onUpdate:checked": $event => option.checked = $event
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(option.name), 1 /* TEXT */)]),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["checked", "onUpdate:checked"])])]),
    dropdownRender: _withCtx(({
      menuNode: menu
    }) => [_createCommentVNode(" 下拉列表，必须 "), _createVNode($setup["VNodes"], {
      vnodes: menu
    }, null, 8 /* PROPS */, ["vnodes"]), _createCommentVNode(" 固定全部选项 "), _createVNode(_component_a_divider, {
      style: {
        "margin": "4px 0"
      }
    }), _createElementVNode("div", {
      style: {
        "padding": "4px 8px",
        "cursor": "pointer"
      },
      onMousedown: _cache[0] || (_cache[0] = e => e.preventDefault()),
      onClick: $setup.btnAll
    }, [_createVNode(_component_plus_outlined), _createVNode($setup["aCheckbox"], {
      style: {
        "pointer-events": "none"
      },
      checked: $setup.checkedAll
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("全部选择")])),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["checked"])], 32 /* NEED_HYDRATION */)]),
    menuItemSelectedIcon: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("span", null, null, -1 /* HOISTED */)])),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["value", "fieldNames", "options"])]);
}