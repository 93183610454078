import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, renderList as _renderList, createSlots as _createSlots } from "vue";
const _hoisted_1 = {
  ref: "wrapRef",
  style: {
    "height": "100%"
  }
};
const _hoisted_2 = {
  key: 0,
  class: "leftBox"
};
const _hoisted_3 = {
  style: {
    width: '100%'
  }
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  key: 1,
  class: "page"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Col = _resolveComponent("Col");
  const _component_i_table = _resolveComponent("i-table");
  const _component_Page = _resolveComponent("Page");
  const _component_Row = _resolveComponent("Row");
  const _component_Card = _resolveComponent("Card");
  const _component_i_drawer_edit = _resolveComponent("i-drawer-edit");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.filter.length > 0 ? (_openBlock(), _createBlock($setup["SearchForm"], {
    key: 0,
    ref: "formRef",
    fields: $props.filter,
    onOnSearch: $setup.onFilterChange,
    onOnReset: $setup.onFilterChange,
    onOnFold: $setup.onFold
  }, null, 8 /* PROPS */, ["fields"])) : _createCommentVNode("v-if", true), _createCommentVNode("  <i-filter ref=\"formRef\" :fields=\"filter\" @on-change=\"onFilterChange\"/>"), _createVNode(_component_Card, {
    "dis-hover": "",
    bordered: false,
    padding: 6,
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_Row, {
      style: {
        "width": "calc(100%)"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_Col, {
        span: "4"
      }, {
        default: _withCtx(() => [_ctx.$slots.leftBox ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "leftBox", {}, undefined, true)])) : _createCommentVNode("v-if", true)]),
        _: 3 /* FORWARDED */
      }), _createVNode(_component_Col, {
        span: _ctx.$slots.leftBox ? 20 : 24
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_ctx.$slots.headerBox ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_renderSlot(_ctx.$slots, "headerBox", {}, undefined, true)])) : _createCommentVNode("v-if", true), _createVNode(_component_i_table, {
          "row-class-name": $props.rowClassName,
          "disabled-hover": true,
          "span-method": $props.spanMethodStatus && $setup.objectSpanMethod,
          border: "",
          size: "small",
          ref: "refTable",
          height: _ctx.$slots.headerBox ? $setup.calcTableHeight - 78 : $setup.calcTableHeight,
          columns: $props.columns,
          loading: $setup.tableLoadingStatus,
          data: $setup.tableData,
          "summary-method": $props.summaryMethod,
          "show-summary": $props.showSummary
        }, _createSlots({
          SysEdit: _withCtx(({
            row,
            column,
            index
          }) => [_createElementVNode("span", {
            style: {
              "cursor": "pointer",
              "color": "#3e80d2"
            },
            onClick: $event => $setup.onRowEditClick(row[column.key], index, row)
          }, _toDisplayString(row[column.key]), 9 /* TEXT, PROPS */, _hoisted_5)]),
          SysDelete: _withCtx(({
            row,
            column,
            index
          }) => [row.fisDeleted == false ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [_createTextVNode("未删除")], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock(_Fragment, {
            key: 1
          }, [_createTextVNode("已删除")], 64 /* STABLE_FRAGMENT */))]),
          operationResult: _withCtx(({
            row,
            column
          }) => [_createElementVNode("div", null, _toDisplayString(row.FModifyUserName), 1 /* TEXT */), _createElementVNode("div", null, _toDisplayString(row.FModifyTime), 1 /* TEXT */), _createElementVNode("div", null, [row.FModifyUserName ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: _normalizeClass('text-blue-600'),
            onClick: $event => $setup.onHistory(row, $props.recordQueryKey)
          }, "查看详情", 8 /* PROPS */, _hoisted_6)) : _createCommentVNode("v-if", true)]), _cache[0] || (_cache[0] = _createElementVNode("div", null, [_createElementVNode("div")], -1 /* HOISTED */))]),
          _: 2 /* DYNAMIC */
        }, [_renderList(_ctx.$slots, (index, name) => {
          return {
            name: name,
            fn: _withCtx(data => [['SysEdit', 'SysDrawerEdit'].indexOf(name) < 0 ? _renderSlot(_ctx.$slots, name, _normalizeProps(_mergeProps({
              key: 0
            }, data)), undefined, true) : _createCommentVNode("v-if", true)])
          };
        })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["row-class-name", "span-method", "height", "columns", "loading", "data", "summary-method", "show-summary"]), $props.pageShow ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_Page, {
          "page-size-opts": [20, 50, 100, 200, 500],
          "model-value": $setup.listPageIndex,
          size: "small",
          "show-total": "",
          "show-sizer": "",
          "page-size": $setup.listPageSize,
          total: $setup.listPageTotal,
          onOnChange: $setup.onPageChange,
          onOnPageSizeChange: $setup.onPageSizeChange
        }, null, 8 /* PROPS */, ["model-value", "page-size", "total"])])) : _createCommentVNode("v-if", true)])]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["span"])]),
      _: 3 /* FORWARDED */
    }), _cache[1] || (_cache[1] = _createElementVNode("div", {
      class: "d-flex"
    }, null, -1 /* HOISTED */))]),
    _: 3 /* FORWARDED */
  }), _createVNode(_component_i_drawer_edit, {
    ref: "refDrawerEdit",
    url: $setup.url,
    PrimaryKey: $setup.PrimaryKey,
    onOnSave: $setup.getList
  }, {
    default: _withCtx(({
      editId,
      editData
    }) => [_renderSlot(_ctx.$slots, "SysDrawerEdit", {
      editId: editId,
      editData: editData
    }, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["url", "PrimaryKey"])], 512 /* NEED_PATCH */);
}