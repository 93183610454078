import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "i-layout-header-trigger i-layout-header-trigger-min"
};
const _hoisted_2 = {
  key: 1,
  class: "i-layout-header-user-name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Avatar = _resolveComponent("Avatar");
  const _component_Icon = _resolveComponent("Icon");
  const _component_DropdownItem = _resolveComponent("DropdownItem");
  const _component_DropdownMenu = _resolveComponent("DropdownMenu");
  const _component_Dropdown = _resolveComponent("Dropdown");
  return _openBlock(), _createBlock(_component_Dropdown, {
    trigger: _ctx.isMobile ? 'click' : 'hover',
    class: _normalizeClass(["i-layout-header-user", {
      'i-layout-header-user-mobile': _ctx.isMobile
    }]),
    onOnClick: $setup.handleClick
  }, {
    list: _withCtx(() => [_createVNode(_component_DropdownMenu, null, {
      default: _withCtx(() => [_createCommentVNode("                <i-link to=\"/setting/user\">"), _createCommentVNode("                    <DropdownItem>"), _createCommentVNode("                        <Icon type=\"ios-contact-outline\" />"), _createCommentVNode("                        <span>{{ $t('basicLayout.user.center') }}</span>"), _createCommentVNode("                    </DropdownItem>"), _createCommentVNode("                </i-link>"), _createCommentVNode("                <i-link to=\"/setting/account\">"), _createCommentVNode("                    <DropdownItem>"), _createCommentVNode("                        <Icon type=\"ios-settings-outline\" />"), _createCommentVNode("                        <span>{{ $t('basicLayout.user.setting') }}</span>"), _createCommentVNode("                    </DropdownItem>"), _createCommentVNode("                </i-link>"), _createVNode(_component_DropdownItem, {
        divided: "",
        name: "logout"
      }, {
        default: _withCtx(() => [_createVNode(_component_Icon, {
          type: "ios-log-out"
        }), _createElementVNode("span", null, _toDisplayString(_ctx.$t('basicLayout.user.logOut')), 1 /* TEXT */)]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    })]),
    default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [$setup.infoAvatar ? (_openBlock(), _createBlock(_component_Avatar, {
      key: 0,
      size: "small",
      src: $setup.infoAvatar
    }, null, 8 /* PROPS */, ["src"])) : _createCommentVNode("v-if", true), !_ctx.isMobile ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($setup.infoName), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["trigger", "class"]);
}