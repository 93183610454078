import { createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Transition as _Transition, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["href"];
const _hoisted_2 = {
  class: "i-layout-header-right"
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  key: 1,
  ref: "wrapperRef",
  class: "mirco-layout"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Sider = _resolveComponent("Sider");
  const _component_Header = _resolveComponent("Header");
  const _component_router_view = _resolveComponent("router-view");
  const _component_Content = _resolveComponent("Content");
  const _component_Layout = _resolveComponent("Layout");
  const _component_Drawer = _resolveComponent("Drawer");
  const _directive_resize = _resolveDirective("resize");
  return !$options.isMircoApp ? (_openBlock(), _createBlock(_component_Layout, {
    key: 0,
    class: "i-layout",
    ref: "wrapperRef"
  }, {
    default: _withCtx(() => [!_ctx.isMobile && !_ctx.hideSider ? (_openBlock(), _createBlock(_component_Sider, {
      key: 0,
      class: _normalizeClass(["i-layout-sider", $options.siderClasses]),
      width: $options.menuSideWidth
    }, {
      default: _withCtx(() => [_createVNode($setup["iMenuSide"], {
        "hide-logo": $options.isHeaderStick && _ctx.headerFix && $options.showHeader
      }, null, 8 /* PROPS */, ["hide-logo"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class", "width"])) : _createCommentVNode("v-if", true), _createVNode(_component_Layout, {
      class: _normalizeClass(["i-layout-inside", $options.insideClasses])
    }, {
      default: _withCtx(() => [_createVNode(_Transition, {
        name: "fade-quick",
        persisted: ""
      }, {
        default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_Header, {
          ref: "headerRef",
          class: _normalizeClass(["i-layout-header", $options.headerClasses]),
          style: _normalizeStyle($options.headerStyle)
        }, {
          default: _withCtx(() => [_createCommentVNode("                    <i-sidebar />"), _createCommentVNode(" <i-header-logo v-if=\"isMobile && showMobileLogo\" />\r\n                    <i-header-logo v-if=\"!isMobile && isHeaderStick && headerFix\" /> "), (_ctx.isMobile || _ctx.showSiderCollapse) && !_ctx.hideSider ? (_openBlock(), _createBlock($setup["iHeaderCollapse"], {
            key: 0,
            onOnToggleDrawer: $options.handleToggleDrawer
          }, null, 8 /* PROPS */, ["onOnToggleDrawer"])) : _createCommentVNode("v-if", true), _createElementVNode("a", {
            target: "_blank",
            href: 'http://panel.focallure.cn/SCM/salesForecastPage?user=' + $setup.infoName
          }, "供应链备货", 8 /* PROPS */, _hoisted_1), !_ctx.isMobile && _ctx.showReload ? (_openBlock(), _createBlock($setup["iHeaderReload"], {
            key: 1,
            onOnReload: $options.handleReload
          }, null, 8 /* PROPS */, ["onOnReload"])) : _createCommentVNode("v-if", true), _ctx.headerMenu && !_ctx.isMobile ? (_openBlock(), _createBlock($setup["iMenuHead"], {
            key: 2,
            ref: "menuHead"
          }, null, 512 /* NEED_PATCH */)) : _createCommentVNode("v-if", true), _ctx.showBreadcrumb && !_ctx.headerMenu && !_ctx.isMobile ? (_openBlock(), _createBlock($setup["iHeaderBreadcrumb"], {
            key: 3,
            ref: "breadcrumb"
          }, null, 512 /* NEED_PATCH */)) : _createCommentVNode("v-if", true), _ctx.showSearch && !_ctx.headerMenu && !_ctx.isMobile && !_ctx.showBreadcrumb ? (_openBlock(), _createBlock($setup["iHeaderSearch"], {
            key: 4
          })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_cache[1] || (_cache[1] = _createElementVNode("a", {
            target: "_blank",
            href: 'https://ding.cjfx.cn/f/dico0g8y'
          }, "意见反馈", -1 /* HOISTED */)), _ctx.showSearch && _ctx.isMobile || _ctx.showSearch && (_ctx.headerMenu || _ctx.showBreadcrumb) ? (_openBlock(), _createBlock($setup["iHeaderSearch"], {
            key: 0
          })) : _createCommentVNode("v-if", true), _ctx.headerMenu && _ctx.isMobile ? (_openBlock(), _createBlock($setup["iMenuHead"], {
            key: 1
          })) : _createCommentVNode("v-if", true), _ctx.isDesktop && _ctx.showLog ? (_openBlock(), _createBlock($setup["iHeaderLog"], {
            key: 2
          })) : _createCommentVNode("v-if", true), _ctx.isDesktop && _ctx.showFullscreen ? (_openBlock(), _createBlock($setup["iHeaderFullscreen"], {
            key: 3
          })) : _createCommentVNode("v-if", true), _ctx.showNotice ? (_openBlock(), _createBlock($setup["iHeaderNotice"], {
            key: 4
          })) : _createCommentVNode("v-if", true), _createVNode($setup["iHeaderUser"]), _ctx.showI18n ? (_openBlock(), _createBlock($setup["iHeaderI18n"], {
            key: 5
          })) : _createCommentVNode("v-if", true), _ctx.enableSetting && !_ctx.isMobile ? (_openBlock(), _createBlock($setup["iHeaderSetting"], {
            key: 6
          })) : _createCommentVNode("v-if", true)])]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class", "style"])), [[_vShow, $options.showHeader], [_directive_resize, $options.handleHeaderWidthChange]])]),
        _: 1 /* STABLE */
      }), _createVNode(_component_Content, {
        class: _normalizeClass(["i-layout-content", $options.contentClasses])
      }, {
        default: _withCtx(() => [_createVNode(_Transition, {
          name: "fade-quick"
        }, {
          default: _withCtx(() => [_ctx.tabs ? _withDirectives((_openBlock(), _createBlock($setup["iTabs"], {
            key: 0,
            ref: "tabsRef",
            onOnReload: $options.handleReload
          }, null, 8 /* PROPS */, ["onOnReload"])), [[_vShow, $options.showHeader]]) : _createCommentVNode("v-if", true)]),
          _: 1 /* STABLE */
        }), _createElementVNode("div", {
          class: "i-layout-content-main",
          ref: "contentRef",
          style: _normalizeStyle($setup.routeMeta.overflowVisible ? {} : {
            height: $setup.contentHeight + 'px',
            minHeight: $setup.contentHeight + 'px',
            overflow: 'hidden'
          })
        }, [_createCommentVNode("                    <router-view #default=\"{ Component }\">"), _createCommentVNode("                        <keep-alive :include=\"[...keepAlive]\">"), _createCommentVNode("                            <component :is=\"Component\" :key=\"route.name\" v-if=\"loadRouter\" />"), _createCommentVNode("                        </keep-alive>"), _createCommentVNode("                    </router-view>"), _createVNode(_component_router_view)], 4 /* STYLE */)]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class"]), _createCommentVNode(" <i-copyright /> ")]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class"]), _ctx.isMobile && !_ctx.hideSider ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_Drawer, {
      modelValue: $data.showDrawer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.showDrawer = $event),
      placement: "left",
      closable: false,
      "class-name": $options.drawerClasses
    }, {
      default: _withCtx(() => [_createVNode($setup["iMenuSide"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "class-name"])])) : _createCommentVNode("v-if", true), _createVNode($setup["iWaterMark"])]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */)) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", {
    class: "i-layout-content-main-app",
    ref: "contentRef",
    style: _normalizeStyle($setup.routeMeta.overflowVisible ? {} : {
      height: $setup.contentHeight + 'px',
      minHeight: $setup.contentHeight + 'px',
      overflow: 'hidden'
    })
  }, [_createVNode(_component_router_view)], 4 /* STYLE */)], 512 /* NEED_PATCH */));
}