import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/svg/nav-theme-dark.svg';
import _imports_1 from '@/assets/svg/nav-theme-light.svg';
import _imports_2 from '@/assets/svg/header-theme-dark.svg';
import _imports_3 from '@/assets/svg/header-theme-primary.svg';
const _hoisted_1 = {
  class: "i-layout-header-setting-item"
};
const _hoisted_2 = {
  class: "i-layout-header-setting-item"
};
const _hoisted_3 = {
  class: "i-layout-header-setting-item"
};
const _hoisted_4 = {
  class: "i-layout-header-setting-item-action"
};
const _hoisted_5 = {
  class: "i-layout-header-setting-item"
};
const _hoisted_6 = {
  class: "i-layout-header-setting-item-action"
};
const _hoisted_7 = {
  class: "i-layout-header-setting-item"
};
const _hoisted_8 = {
  class: "i-layout-header-setting-item-action"
};
const _hoisted_9 = {
  class: "i-layout-header-setting-item-desc"
};
const _hoisted_10 = {
  class: "i-layout-header-setting-item-action"
};
const _hoisted_11 = {
  class: "i-layout-header-setting-item-desc"
};
const _hoisted_12 = {
  class: "i-layout-header-setting-item-action"
};
const _hoisted_13 = {
  class: "i-layout-header-setting-item"
};
const _hoisted_14 = {
  class: "i-layout-header-setting-item-action"
};
const _hoisted_15 = {
  class: "i-layout-header-setting-item"
};
const _hoisted_16 = {
  class: "i-layout-header-setting-item-action"
};
const _hoisted_17 = {
  class: "i-layout-header-setting-item"
};
const _hoisted_18 = {
  class: "i-layout-header-setting-item-action"
};
const _hoisted_19 = {
  class: "i-layout-header-setting-item"
};
const _hoisted_20 = {
  class: "i-layout-header-setting-item-desc"
};
const _hoisted_21 = {
  class: "i-layout-header-setting-item-action"
};
const _hoisted_22 = {
  class: "i-layout-header-setting-item-desc"
};
const _hoisted_23 = {
  class: "i-layout-header-setting-item-action"
};
const _hoisted_24 = {
  class: "i-layout-header-setting-item"
};
const _hoisted_25 = {
  class: "i-layout-header-setting-item-action"
};
const _hoisted_26 = {
  class: "i-layout-header-setting-item"
};
const _hoisted_27 = {
  class: "i-layout-header-setting-item-action"
};
const _hoisted_28 = {
  class: "i-layout-header-setting-item"
};
const _hoisted_29 = {
  class: "i-layout-header-setting-item-action"
};
const _hoisted_30 = {
  class: "i-layout-header-setting-item-desc"
};
const _hoisted_31 = {
  class: "i-layout-header-setting-item-action"
};
const _hoisted_32 = {
  class: "i-layout-header-setting-item-desc"
};
const _hoisted_33 = {
  class: "i-layout-header-setting-item-action"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_Divider = _resolveComponent("Divider");
  const _component_Tooltip = _resolveComponent("Tooltip");
  const _component_Switch = _resolveComponent("Switch");
  const _component_Alert = _resolveComponent("Alert");
  const _component_Drawer = _resolveComponent("Drawer");
  return _openBlock(), _createElementBlock("span", {
    class: "i-layout-header-trigger i-layout-header-trigger-min",
    onClick: _cache[21] || (_cache[21] = (...args) => $options.showSetting && $options.showSetting(...args))
  }, [_createVNode(_component_Icon, {
    type: "md-more"
  }), _createVNode(_component_Drawer, {
    modelValue: $data.visible,
    "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => $data.visible = $event),
    width: "280"
  }, {
    default: _withCtx(() => [_createVNode(_component_Divider, {
      size: "small"
    }, {
      default: _withCtx(() => _cache[22] || (_cache[22] = [_createTextVNode("主题风格设置")])),
      _: 1 /* STABLE */
    }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
      class: _normalizeClass(["i-layout-header-setting-item-radio", {
        'on': _ctx.siderTheme === 'dark'
      }]),
      onClick: _cache[0] || (_cache[0] = $event => $options.handleChangeSetting('siderTheme', 'dark'))
    }, [_createVNode(_component_Tooltip, {
      content: "暗色侧边栏",
      placement: "top",
      transfer: ""
    }, {
      default: _withCtx(() => _cache[23] || (_cache[23] = [_createElementVNode("img", {
        src: _imports_0
      }, null, -1 /* HOISTED */)])),
      _: 1 /* STABLE */
    })], 2 /* CLASS */), _createElementVNode("div", {
      class: _normalizeClass(["i-layout-header-setting-item-radio", {
        'on': _ctx.siderTheme === 'light'
      }]),
      onClick: _cache[1] || (_cache[1] = $event => $options.handleChangeSetting('siderTheme', 'light'))
    }, [_createVNode(_component_Tooltip, {
      content: "亮色侧边栏",
      placement: "top",
      transfer: ""
    }, {
      default: _withCtx(() => _cache[24] || (_cache[24] = [_createElementVNode("img", {
        src: _imports_1
      }, null, -1 /* HOISTED */)])),
      _: 1 /* STABLE */
    })], 2 /* CLASS */)]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
      class: _normalizeClass(["i-layout-header-setting-item-radio", {
        'on': _ctx.headerTheme === 'light'
      }]),
      onClick: _cache[2] || (_cache[2] = $event => $options.handleChangeSetting('headerTheme', 'light'))
    }, [_createVNode(_component_Tooltip, {
      content: "亮色顶栏",
      placement: "top",
      transfer: ""
    }, {
      default: _withCtx(() => _cache[25] || (_cache[25] = [_createElementVNode("img", {
        src: _imports_0
      }, null, -1 /* HOISTED */)])),
      _: 1 /* STABLE */
    })], 2 /* CLASS */), _createElementVNode("div", {
      class: _normalizeClass(["i-layout-header-setting-item-radio", {
        'on': _ctx.headerTheme === 'dark'
      }]),
      onClick: _cache[3] || (_cache[3] = $event => $options.handleChangeSetting('headerTheme', 'dark'))
    }, [_createVNode(_component_Tooltip, {
      content: "暗色顶栏",
      placement: "top",
      transfer: ""
    }, {
      default: _withCtx(() => _cache[26] || (_cache[26] = [_createElementVNode("img", {
        src: _imports_2
      }, null, -1 /* HOISTED */)])),
      _: 1 /* STABLE */
    })], 2 /* CLASS */), _createElementVNode("div", {
      class: _normalizeClass(["i-layout-header-setting-item-radio", {
        'on': _ctx.headerTheme === 'primary'
      }]),
      onClick: _cache[4] || (_cache[4] = $event => $options.handleChangeSetting('headerTheme', 'primary'))
    }, [_createVNode(_component_Tooltip, {
      content: "主色顶栏",
      placement: "top",
      transfer: ""
    }, {
      default: _withCtx(() => _cache[27] || (_cache[27] = [_createElementVNode("img", {
        src: _imports_3
      }, null, -1 /* HOISTED */)])),
      _: 1 /* STABLE */
    })], 2 /* CLASS */)]), _createVNode(_component_Divider, {
      size: "small"
    }, {
      default: _withCtx(() => _cache[28] || (_cache[28] = [_createTextVNode("导航设置")])),
      _: 1 /* STABLE */
    }), _createElementVNode("div", _hoisted_3, [_cache[29] || (_cache[29] = _createElementVNode("span", {
      class: "i-layout-header-setting-item-desc"
    }, "显示顶栏菜单", -1 /* HOISTED */)), _createElementVNode("span", _hoisted_4, [_createVNode(_component_Switch, {
      size: "small",
      "model-value": _ctx.headerMenu,
      onOnChange: _cache[5] || (_cache[5] = val => $options.handleChangeSetting('headerMenu', val))
    }, null, 8 /* PROPS */, ["model-value"])])]), _createElementVNode("div", _hoisted_5, [_cache[30] || (_cache[30] = _createElementVNode("span", {
      class: "i-layout-header-setting-item-desc"
    }, "固定侧边栏", -1 /* HOISTED */)), _createElementVNode("span", _hoisted_6, [_createVNode(_component_Switch, {
      size: "small",
      "model-value": _ctx.siderFix,
      onOnChange: _cache[6] || (_cache[6] = val => $options.handleChangeSetting('siderFix', val))
    }, null, 8 /* PROPS */, ["model-value"])])]), _createElementVNode("div", _hoisted_7, [_cache[31] || (_cache[31] = _createElementVNode("span", {
      class: "i-layout-header-setting-item-desc"
    }, "固定顶栏", -1 /* HOISTED */)), _createElementVNode("span", _hoisted_8, [_createVNode(_component_Switch, {
      size: "small",
      "model-value": _ctx.headerFix,
      onOnChange: _cache[7] || (_cache[7] = val => $options.handleChangeSetting('headerFix', val))
    }, null, 8 /* PROPS */, ["model-value"])])]), _createElementVNode("div", {
      class: _normalizeClass(["i-layout-header-setting-item", {
        'i-layout-header-setting-item-disabled': !_ctx.headerFix
      }])
    }, [_createElementVNode("span", _hoisted_9, [_cache[32] || (_cache[32] = _createTextVNode(" 下滑时隐藏顶栏 ")), _createVNode(_component_Tooltip, {
      placement: "top",
      content: "需开启固定顶栏",
      transfer: ""
    }, {
      default: _withCtx(() => [_createVNode(_component_Icon, {
        type: "ios-help-circle-outline"
      })]),
      _: 1 /* STABLE */
    })]), _createElementVNode("span", _hoisted_10, [_createVNode(_component_Switch, {
      size: "small",
      "model-value": _ctx.headerHide,
      disabled: !_ctx.headerFix,
      onOnChange: _cache[8] || (_cache[8] = val => $options.handleChangeSetting('headerHide', val))
    }, null, 8 /* PROPS */, ["model-value", "disabled"])])], 2 /* CLASS */), _createElementVNode("div", {
      class: _normalizeClass(["i-layout-header-setting-item", {
        'i-layout-header-setting-item-disabled': !_ctx.headerFix
      }])
    }, [_createElementVNode("span", _hoisted_11, [_cache[33] || (_cache[33] = _createTextVNode(" 置顶顶栏 ")), _createVNode(_component_Tooltip, {
      placement: "top",
      content: "需开启固定顶栏",
      transfer: ""
    }, {
      default: _withCtx(() => [_createVNode(_component_Icon, {
        type: "ios-help-circle-outline"
      })]),
      _: 1 /* STABLE */
    })]), _createElementVNode("span", _hoisted_12, [_createVNode(_component_Switch, {
      size: "small",
      "model-value": _ctx.headerStick,
      disabled: !_ctx.headerFix,
      onOnChange: _cache[9] || (_cache[9] = val => $options.handleChangeSetting('headerStick', val))
    }, null, 8 /* PROPS */, ["model-value", "disabled"])])], 2 /* CLASS */), _createElementVNode("div", _hoisted_13, [_cache[34] || (_cache[34] = _createElementVNode("span", {
      class: "i-layout-header-setting-item-desc"
    }, "侧边栏开启手风琴模式", -1 /* HOISTED */)), _createElementVNode("span", _hoisted_14, [_createVNode(_component_Switch, {
      size: "small",
      "model-value": _ctx.menuAccordion,
      onOnChange: _cache[10] || (_cache[10] = val => $options.handleChangeSetting('menuAccordion', val))
    }, null, 8 /* PROPS */, ["model-value"])])]), _createElementVNode("div", _hoisted_15, [_cache[35] || (_cache[35] = _createElementVNode("span", {
      class: "i-layout-header-setting-item-desc"
    }, "显示折叠侧边栏按钮", -1 /* HOISTED */)), _createElementVNode("span", _hoisted_16, [_createVNode(_component_Switch, {
      size: "small",
      "model-value": _ctx.showSiderCollapse,
      onOnChange: _cache[11] || (_cache[11] = val => $options.handleChangeSetting('showSiderCollapse', val))
    }, null, 8 /* PROPS */, ["model-value"])])]), _createElementVNode("div", _hoisted_17, [_cache[36] || (_cache[36] = _createElementVNode("span", {
      class: "i-layout-header-setting-item-desc"
    }, "侧边栏折叠时显示父级菜单名", -1 /* HOISTED */)), _createElementVNode("span", _hoisted_18, [_createVNode(_component_Switch, {
      size: "small",
      "model-value": _ctx.showCollapseMenuTitle,
      onOnChange: _cache[12] || (_cache[12] = val => $options.handleChangeSetting('showCollapseMenuTitle', val))
    }, null, 8 /* PROPS */, ["model-value"])])]), _createElementVNode("div", _hoisted_19, [_createElementVNode("span", _hoisted_20, [_cache[37] || (_cache[37] = _createTextVNode(" 显示全局面包屑导航 ")), _createVNode(_component_Tooltip, {
      placement: "top",
      content: "headerMenu 开启时无效",
      transfer: ""
    }, {
      default: _withCtx(() => [_createVNode(_component_Icon, {
        type: "ios-help-circle-outline"
      })]),
      _: 1 /* STABLE */
    })]), _createElementVNode("span", _hoisted_21, [_createVNode(_component_Switch, {
      size: "small",
      "model-value": _ctx.showBreadcrumb,
      onOnChange: _cache[13] || (_cache[13] = val => $options.handleChangeSetting('showBreadcrumb', val))
    }, null, 8 /* PROPS */, ["model-value"])])]), _createElementVNode("div", {
      class: _normalizeClass(["i-layout-header-setting-item", {
        'i-layout-header-setting-item-disabled': !_ctx.showBreadcrumb
      }])
    }, [_createElementVNode("span", _hoisted_22, [_cache[38] || (_cache[38] = _createTextVNode(" 全局面包屑显示图标 ")), _createVNode(_component_Tooltip, {
      placement: "top",
      content: "需开启全局面包屑导航",
      transfer: ""
    }, {
      default: _withCtx(() => [_createVNode(_component_Icon, {
        type: "ios-help-circle-outline"
      })]),
      _: 1 /* STABLE */
    })]), _createElementVNode("span", _hoisted_23, [_createVNode(_component_Switch, {
      size: "small",
      "model-value": _ctx.showBreadcrumbIcon,
      disabled: !_ctx.showBreadcrumb,
      onOnChange: _cache[14] || (_cache[14] = val => $options.handleChangeSetting('showBreadcrumbIcon', val))
    }, null, 8 /* PROPS */, ["model-value", "disabled"])])], 2 /* CLASS */), _createElementVNode("div", _hoisted_24, [_cache[39] || (_cache[39] = _createElementVNode("span", {
      class: "i-layout-header-setting-item-desc"
    }, "显示重载页面按钮", -1 /* HOISTED */)), _createElementVNode("span", _hoisted_25, [_createVNode(_component_Switch, {
      size: "small",
      "model-value": _ctx.showReload,
      onOnChange: _cache[15] || (_cache[15] = val => $options.handleChangeSetting('showReload', val))
    }, null, 8 /* PROPS */, ["model-value"])])]), _createElementVNode("div", _hoisted_26, [_cache[40] || (_cache[40] = _createElementVNode("span", {
      class: "i-layout-header-setting-item-desc"
    }, "显示多语言选择", -1 /* HOISTED */)), _createElementVNode("span", _hoisted_27, [_createVNode(_component_Switch, {
      size: "small",
      "model-value": _ctx.showI18n,
      onOnChange: _cache[16] || (_cache[16] = val => $options.handleChangeSetting('showI18n', val))
    }, null, 8 /* PROPS */, ["model-value"])])]), _createVNode(_component_Divider, {
      size: "small"
    }, {
      default: _withCtx(() => _cache[41] || (_cache[41] = [_createTextVNode("其它设置")])),
      _: 1 /* STABLE */
    }), _createElementVNode("div", _hoisted_28, [_cache[42] || (_cache[42] = _createElementVNode("span", {
      class: "i-layout-header-setting-item-desc"
    }, "开启多页签", -1 /* HOISTED */)), _createElementVNode("span", _hoisted_29, [_createVNode(_component_Switch, {
      size: "small",
      "model-value": _ctx.tabs,
      onOnChange: _cache[17] || (_cache[17] = val => $options.handleChangeSetting('tabs', val))
    }, null, 8 /* PROPS */, ["model-value"])])]), _createElementVNode("div", {
      class: _normalizeClass(["i-layout-header-setting-item", {
        'i-layout-header-setting-item-disabled': !_ctx.tabs
      }])
    }, [_createElementVNode("span", _hoisted_30, [_cache[43] || (_cache[43] = _createTextVNode(" 多页签显示图标 ")), _createVNode(_component_Tooltip, {
      placement: "top",
      content: "需开启多页签",
      transfer: ""
    }, {
      default: _withCtx(() => [_createVNode(_component_Icon, {
        type: "ios-help-circle-outline"
      })]),
      _: 1 /* STABLE */
    })]), _createElementVNode("span", _hoisted_31, [_createVNode(_component_Switch, {
      size: "small",
      "model-value": _ctx.showTabsIcon,
      disabled: !_ctx.tabs,
      onOnChange: _cache[18] || (_cache[18] = val => $options.handleChangeSetting('showTabsIcon', val))
    }, null, 8 /* PROPS */, ["model-value", "disabled"])])], 2 /* CLASS */), _createElementVNode("div", {
      class: _normalizeClass(["i-layout-header-setting-item", {
        'i-layout-header-setting-item-disabled': !_ctx.tabs
      }])
    }, [_createElementVNode("span", _hoisted_32, [_cache[44] || (_cache[44] = _createTextVNode(" 固定多页签 ")), _createVNode(_component_Tooltip, {
      placement: "top",
      content: "需开启多页签",
      transfer: ""
    }, {
      default: _withCtx(() => [_createVNode(_component_Icon, {
        type: "ios-help-circle-outline"
      })]),
      _: 1 /* STABLE */
    })]), _createElementVNode("span", _hoisted_33, [_createVNode(_component_Switch, {
      size: "small",
      "model-value": _ctx.tabsFix,
      disabled: !_ctx.tabs,
      onOnChange: _cache[19] || (_cache[19] = val => $options.handleChangeSetting('tabsFix', val))
    }, null, 8 /* PROPS */, ["model-value", "disabled"])])], 2 /* CLASS */), _createVNode(_component_Alert, {
      type: "warning"
    }, {
      desc: _withCtx(() => _cache[45] || (_cache[45] = [_createElementVNode("div", null, [_createTextVNode(" 该功能主要实时预览各种布局效果，更多完整配置在 "), _createElementVNode("strong", null, "setting.js"), _createTextVNode(" 中设置。建议在生产环境关闭该布局预览功能。 ")], -1 /* HOISTED */)])),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"])]);
}