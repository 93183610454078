import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "t-menu"
};
const _hoisted_2 = {
  class: "btn"
};
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_DropdownItem = _resolveComponent("DropdownItem");
  const _component_DropdownMenu = _resolveComponent("DropdownMenu");
  const _component_Dropdown = _resolveComponent("Dropdown");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.rule, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index
    }, [item.Child ? (_openBlock(), _createBlock(_component_Dropdown, {
      key: 0,
      placement: "bottom-start",
      onOnClick: $setup.handleClick
    }, {
      list: _withCtx(() => [_createVNode(_component_DropdownMenu, null, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.Child, (child, index2) => {
          return _openBlock(), _createBlock(_component_DropdownItem, {
            key: index2,
            name: child.Event
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(child.Title), 1 /* TEXT */)]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name"]);
        }), 128 /* KEYED_FRAGMENT */))]),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)]),
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, _toDisplayString(item.Title), 1 /* TEXT */), _createVNode(_component_Icon, {
        size: "18",
        type: "md-arrow-dropdown"
      })])]),
      _: 2 /* DYNAMIC */
    }, 1024 /* DYNAMIC_SLOTS */)) : (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: "btn",
      onClick: $event => $setup.handleClick(item.Event)
    }, [_createCommentVNode("              <Button :size=\"'small'\" type=\"primary\">"), _createCommentVNode("              </Button>"), _createTextVNode(" " + _toDisplayString(item.Title), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_3)), item.Divided ? (_openBlock(), _createElementBlock("div", {
      class: "divided",
      key: item.Event
    })) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))]);
}