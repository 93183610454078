import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withKeys as _withKeys, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "search-form"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Input = _resolveComponent("Input");
  const _component_InputNumber = _resolveComponent("InputNumber");
  const _component_Option = _resolveComponent("Option");
  const _component_Select = _resolveComponent("Select");
  const _component_DatePicker = _resolveComponent("DatePicker");
  const _component_TreeSelect = _resolveComponent("TreeSelect");
  const _component_FormItem = _resolveComponent("FormItem");
  const _component_Col = _resolveComponent("Col");
  const _component_Button = _resolveComponent("Button");
  const _component_Icon = _resolveComponent("Icon");
  const _component_Row = _resolveComponent("Row");
  const _component_Form = _resolveComponent("Form");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Form, {
    ref: "formInline",
    class: _normalizeClass([$setup.foldStatus ? 'fold' : '']),
    model: $setup.fromValue,
    inline: "",
    style: {
      "flex-wrap": "wrap"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_Row, null, {
      default: _withCtx(() => [_createVNode(_component_Col, {
        xs: 15,
        sm: 17,
        md: 18,
        lg: 19,
        xl: 20,
        xxl: 20,
        style: {
          "display": "flex",
          "flex-wrap": "wrap"
        }
      }, {
        default: _withCtx(() => [_createCommentVNode("<Row>"), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.fields, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index,
            span: "6",
            xs: 24,
            sm: 12,
            xl: 8,
            xxl: 4
          }, [_createVNode(_component_FormItem, {
            label: item.title,
            "label-width": item.labelWidth || 90
          }, {
            default: _withCtx(() => [_createElementVNode("div", null, [item.component === 'input' ? (_openBlock(), _createBlock(_component_Input, {
              key: 0,
              ref_for: true,
              ref: "inputRef",
              modelValue: $setup.fromValue[item.value],
              "onUpdate:modelValue": $event => $setup.fromValue[item.value] = $event,
              modelModifiers: {
                trim: true
              },
              class: _normalizeClass($setup.calcOffsetTop($setup.inputRef) ? '' : ''),
              placeholder: `请输入${item.title}`,
              clearable: "",
              style: {
                "width": "220px"
              },
              onKeyup: _withKeys($event => $setup.fromValue[item.value] && $setup.handleSubmit('formInline'), ["enter"]),
              type: "text"
            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "class", "placeholder", "onKeyup"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", null, [item.component === 'inputIcon' ? (_openBlock(), _createBlock($setup["InputIcon"], {
              key: 0,
              ref_for: true,
              ref: "inputRef",
              modelValue: $setup.fromValue[item.value],
              "onUpdate:modelValue": $event => $setup.fromValue[item.value] = $event,
              modelModifiers: {
                trim: true
              },
              class: _normalizeClass($setup.calcOffsetTop($setup.inputRef) ? '' : ''),
              placeholder: `请输入${item.title}`,
              clearable: "",
              style: {
                "width": "220px"
              },
              onKeyup: _withKeys($event => $setup.fromValue[item.value] && $setup.handleSubmit('formInline'), ["enter"]),
              type: "text"
            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "class", "placeholder", "onKeyup"])) : _createCommentVNode("v-if", true)]), _createCommentVNode("             客户搜索 多个逗号隔开"), item.component === 'input-multiple' ? (_openBlock(), _createBlock(_component_Input, {
              key: 0,
              ref_for: true,
              ref: "inputRef2",
              modelValue: $setup.fromValue[item.value],
              "onUpdate:modelValue": $event => $setup.fromValue[item.value] = $event,
              modelModifiers: {
                trim: true
              },
              class: _normalizeClass($setup.calcOffsetTop($setup.inputRef) ? '' : ''),
              onKeyup: _withKeys($event => $setup.fromValue[item.value] && $setup.handleSubmit('formInline'), ["enter"]),
              placeholder: `请输入${item.title}`,
              clearable: "",
              style: {
                "width": "100%"
              },
              type: "text",
              onPaste: $event => $setup.listionPaste($event, item.value)
            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "class", "onKeyup", "placeholder", "onPaste"])) : _createCommentVNode("v-if", true), item.component === 'input-number' ? (_openBlock(), _createBlock(_component_InputNumber, {
              key: 1,
              ref_for: true,
              ref: "inputNumberRef",
              modelValue: $setup.fromValue[item.value],
              "onUpdate:modelValue": $event => $setup.fromValue[item.value] = $event,
              placeholder: `请输入${item.title}`,
              precision: 0,
              clearable: "",
              style: {
                "width": "100%"
              }
            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "placeholder"])) : _createCommentVNode("v-if", true), item.component === 'select' ? (_openBlock(), _createBlock(_component_Select, {
              key: 2,
              ref_for: true,
              ref: "selectRef",
              modelValue: $setup.fromValue[item.value],
              "onUpdate:modelValue": $event => $setup.fromValue[item.value] = $event,
              transfer: true,
              clearable: "",
              style: {
                "width": "220px"
              }
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.selectOptionList, (op, i) => {
                return _openBlock(), _createBlock(_component_Option, {
                  value: op.value
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(op.label), 1 /* TEXT */)]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]);
              }), 256 /* UNKEYED_FRAGMENT */))]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), item.component === 'dict-select' ? (_openBlock(), _createBlock(_component_Select, {
              key: 3,
              clearable: "",
              transfer: true,
              ref_for: true,
              ref: "dictSelectRef",
              class: _normalizeClass($setup.calcOffsetTop($setup.dictSelectRef) ? '' : ''),
              style: {
                "width": "220px"
              },
              modelValue: $setup.fromValue[item.value],
              "onUpdate:modelValue": $event => $setup.fromValue[item.value] = $event
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.fields[index].presetArr, (op, i) => {
                var _item$props, _item$props$optionPro;
                return _openBlock(), _createBlock(_component_Option, {
                  value: op[((_item$props = item.props) === null || _item$props === void 0 ? void 0 : (_item$props$optionPro = _item$props.optionProps) === null || _item$props$optionPro === void 0 ? void 0 : _item$props$optionPro.valueKey) || 'FCode']
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(op.FChineseValue), 1 /* TEXT */)]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]);
              }), 256 /* UNKEYED_FRAGMENT */))]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "modelValue", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), item.component === 'dict-select-value' ? (_openBlock(), _createBlock(_component_Select, {
              key: 4,
              ref_for: true,
              ref: "selectRef",
              modelValue: $setup.fromValue[item.value],
              "onUpdate:modelValue": $event => $setup.fromValue[item.value] = $event,
              transfer: true,
              clearable: "",
              style: {
                "width": "220px"
              }
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.pdterData, (op, i) => {
                return _openBlock(), _createBlock(_component_Option, {
                  value: op.FCode,
                  key: op.FId
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(op.FChineseValue), 1 /* TEXT */)]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]);
              }), 128 /* KEYED_FRAGMENT */))]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), _createCommentVNode(" 下拉多选 "), item.component === 'select-multiple' ? (_openBlock(), _createBlock(_component_Select, {
              key: 5,
              clearable: "",
              "max-tag-count": 1,
              transfer: true,
              multiple: "",
              style: {
                "width": "220px"
              },
              modelValue: $setup.fromValue[item.value],
              "onUpdate:modelValue": $event => $setup.fromValue[item.value] = $event
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.selectOptionList, (op, i) => {
                return _openBlock(), _createBlock(_component_Option, {
                  value: op.value
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(op.label), 1 /* TEXT */)]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]);
              }), 256 /* UNKEYED_FRAGMENT */))]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), _createCommentVNode("              日历年月日 1号"), item.component === 'date-picker-one' ? (_openBlock(), _createBlock(_component_DatePicker, {
              key: 6,
              options: $setup.pickerOptions,
              clearable: "",
              transfer: true,
              onOnChange: v => $setup.pickerChange(v, item.value),
              type: "daterange",
              placement: "bottom-start",
              placeholder: '',
              "model-value": $setup.fromValue[item.value],
              "onUpdate:modelValue": $event => $setup.fromValue[item.value] = $event,
              style: {
                "width": "220px"
              }
            }, null, 8 /* PROPS */, ["options", "onOnChange", "model-value", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), _createCommentVNode("              日历年月日"), item.component === 'date-picker' ? (_openBlock(), _createBlock(_component_DatePicker, {
              key: 7,
              options: $setup.pickerOptions,
              clearable: "",
              transfer: true,
              onOnChange: v => $setup.pickerChange(v, item.value),
              type: "daterange",
              placement: "bottom-start",
              placeholder: '',
              "model-value": $setup.fromValue[item.value],
              "onUpdate:modelValue": $event => $setup.fromValue[item.value] = $event,
              style: {
                "width": "220px"
              }
            }, null, 8 /* PROPS */, ["options", "onOnChange", "model-value", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), _createCommentVNode("              日历年月"), item.component === 'date-picker-month' ? (_openBlock(), _createBlock(_component_DatePicker, {
              key: 8,
              clearable: "",
              transfer: true,
              type: "month",
              placement: "bottom-start",
              placeholder: '',
              "model-value": $setup.fromValue[item.value],
              "onUpdate:modelValue": $event => $setup.fromValue[item.value] = $event,
              style: {
                "width": "220px"
              }
            }, null, 8 /* PROPS */, ["model-value", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), _createCommentVNode("              日历年月"), item.component === 'date-picker-month1' ? (_openBlock(), _createBlock(_component_DatePicker, {
              key: 9,
              clearable: "",
              transfer: true,
              type: "month",
              placement: "bottom-start",
              placeholder: '',
              "model-value": $setup.fromValue[item.value],
              "onUpdate:modelValue": $event => $setup.fromValue[item.value] = $event,
              style: {
                "width": "220px"
              }
            }, null, 8 /* PROPS */, ["model-value", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), _createCommentVNode("              年"), _createCommentVNode("              年"), item.component === 'date-picker-year' ? (_openBlock(), _createBlock(_component_DatePicker, {
              key: 10,
              clearable: "",
              transfer: true,
              type: "year",
              placement: "bottom-start",
              placeholder: '',
              "model-value": $setup.fromValue[item.value],
              "onUpdate:modelValue": $event => $setup.fromValue[item.value] = $event,
              style: {
                "width": "190px"
              }
            }, null, 8 /* PROPS */, ["model-value", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), _createCommentVNode("         多选 9.26     客户下拉搜索框"), item.component === 'select-search-custom' ? (_openBlock(), _createBlock($setup["SelectCustom"], _mergeProps({
              key: 11,
              clearable: "",
              value: $setup.fromValue[item.value],
              "onUpdate:value": $event => $setup.fromValue[item.value] = $event,
              style: {
                "min-width": "240px"
              },
              ref_for: true
            }, item.props), null, 16 /* FULL_PROPS */, ["value", "onUpdate:value"])) : _createCommentVNode("v-if", true), _createCommentVNode("              店铺下拉搜索框 复选框"), item.component === 'select-search-custom-checkBox' ? (_openBlock(), _createBlock($setup["SelectCustomCheckBox"], _mergeProps({
              key: 12,
              value: $setup.fromValue[item.value],
              "onUpdate:value": $event => $setup.fromValue[item.value] = $event,
              style: {
                "min-width": "220px"
              },
              ref_for: true
            }, item.props), null, 16 /* FULL_PROPS */, ["value", "onUpdate:value"])) : _createCommentVNode("v-if", true), _createCommentVNode("              客户下拉搜索框 复选框"), item.component === 'select-search-cus' ? (_openBlock(), _createBlock($setup["SelectSearchCus"], _mergeProps({
              key: 13,
              value: $setup.fromValue[item.value],
              "onUpdate:value": $event => $setup.fromValue[item.value] = $event,
              style: {
                "min-width": "220px"
              },
              ref_for: true
            }, item.props), null, 16 /* FULL_PROPS */, ["value", "onUpdate:value"])) : _createCommentVNode("v-if", true), _createCommentVNode("              核销明细搜索框 复选框"), item.component === 'select-search-custom-checkBoxFid' ? (_openBlock(), _createBlock($setup["SelectCustomCheckBoxFid"], _mergeProps({
              key: 14,
              value: $setup.fromValue[item.value],
              "onUpdate:value": $event => $setup.fromValue[item.value] = $event,
              style: {
                "min-width": "220px"
              },
              ref_for: true
            }, item.props), null, 16 /* FULL_PROPS */, ["value", "onUpdate:value"])) : _createCommentVNode("v-if", true), _createCommentVNode("              出库和退货 搜索框 复选框"), item.component === 'select-search-custom-checkBoxNo' ? (_openBlock(), _createBlock($setup["SelectCustomCheckBoxNo"], _mergeProps({
              key: 15,
              value: $setup.fromValue[item.value],
              "onUpdate:value": $event => $setup.fromValue[item.value] = $event,
              style: {
                "width": "220px"
              },
              ref_for: true
            }, item.props), null, 16 /* FULL_PROPS */, ["value", "onUpdate:value"])) : _createCommentVNode("v-if", true), item.component === 'select-multiple-type' ? (_openBlock(), _createBlock(_component_Select, {
              key: 16,
              clearable: "",
              "max-tag-count": 1,
              transfer: true,
              multiple: "",
              style: {
                "width": "220px"
              },
              modelValue: $setup.fromValue[item.value],
              "onUpdate:modelValue": $event => $setup.fromValue[item.value] = $event
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.OutStockAbnormalEnumList1, (item, index) => {
                return _openBlock(), _createBlock(_component_Option, {
                  value: item.Key,
                  key: item.Key
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(item.Name), 1 /* TEXT */)]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]);
              }), 128 /* KEYED_FRAGMENT */))]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), _createCommentVNode("              开发进度"), _createCommentVNode("              <DatePicker clearable :transfer=\"true\" @on-change=\"(v) => pickerChange(v, item.value)\""), _createCommentVNode("                          v-if=\"item.component === 'date-picker-month'\""), _createCommentVNode("                          type=\"month\" placement=\"bottom-start\""), _createCommentVNode("                          :placeholder=\"''\" v-model:model-value=\"fromValue[item.value]\" style=\"width: 220px\"/>"), item.component === 'tree-select' ? (_openBlock(), _createBlock(_component_TreeSelect, {
              key: 17,
              clearable: "",
              transfer: true,
              modelValue: $setup.fromValue[item.value],
              "onUpdate:modelValue": $event => $setup.fromValue[item.value] = $event,
              style: {
                "width": "190px"
              },
              data: item.selectOptionList
            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "data"])) : _createCommentVNode("v-if", true), _createCommentVNode(" 开发人下拉搜索框"), item.component === 'select-search-devPeople' ? (_openBlock(), _createBlock($setup["SelectDevPeople"], _mergeProps({
              key: 18,
              style: {
                "width": "190px"
              },
              ref_for: true
            }, item.props, {
              "search-field-name": 'FName',
              fieldNames: {
                label: 'FName',
                value: 'FName',
                option: {
                  title: 'FName'
                }
              },
              value: $setup.fromValue[item.value],
              "onUpdate:value": $event => $setup.fromValue[item.value] = $event
            }), null, 16 /* FULL_PROPS */, ["value", "onUpdate:value"])) : _createCommentVNode("v-if", true)]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "label-width"])]);
        }), 128 /* KEYED_FRAGMENT */)), _createCommentVNode("          </Row>")]),
        _: 1 /* STABLE */
      }), _createVNode(_component_Col, {
        lg: 5,
        md: 6,
        sm: 7,
        xl: 4,
        xs: 9,
        xxl: 4
      }, {
        default: _withCtx(() => [_createVNode(_component_FormItem, {
          style: {
            "display": "flex",
            "justify-content": "end"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_Button, {
            type: "primary",
            onClick: _cache[0] || (_cache[0] = $event => $setup.handleSubmit('formInline'))
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("搜索")])),
            _: 1 /* STABLE */
          }), _createVNode(_component_Button, {
            class: "ml-1",
            onClick: _cache[1] || (_cache[1] = $event => $setup.handleReset('formInline'))
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("重置")])),
            _: 1 /* STABLE */
          }), $setup.foldButtonShow ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "ml-1",
            style: {
              "color": "#2d8cf0",
              "border": "none !important"
            },
            type: "text",
            icon: "md-arrow-round-down",
            onClick: $setup.handFold
          }, [!$setup.foldStatus ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            type: "md-arrow-round-up"
          })) : (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            type: "md-arrow-round-down"
          })), _createTextVNode(" " + _toDisplayString($setup.foldStatus ? '展开' : '折叠'), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]),
          _: 1 /* STABLE */
        })]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class", "model"])]);
}