/**
 * SOM-WorkOrder
 */
export default {
  path: '/SOM',
  title: '运维辅助',
  children: [{
    title: "工单管理",
    children: [{
      title: "工单",
      children: [{
        path: "/SOM/WorkOrder/List",
        title: '工单列表'
      }]
    }]
  }, {
    title: "数据校对",
    children: [{
      title: "金蝶出库对比OMS",
      children: [{
        path: "/SOM/ErpStockOutInspect/MaBangSaleOrder/List",
        title: '马帮销售订单校验'
      }, {
        path: "/SOM/ErpStockOutInspect/WangDianTongSaleOrder/List",
        title: '旺店通销售订单校验'
      }]
    }]
  }, {
    title: "系统监控",
    children: [{
      title: "用户",
      children: [{
        path: "/SOM/ErpStockOutInspect/MaBangSaleOrder/List",
        title: "在线用户"
      }, {
        path: "/SOM/ErpStockOutInspect/MaBangSaleOrder/List",
        title: "异常日志"
      }]
    }, {
      title: "缓存",
      children: [{
        path: "/SOM/ErpStockOutInspect/MaBangSaleOrder/List",
        title: "Redis服务"
      }, {
        path: "/SOM/ErpStockOutInspect/MaBangSaleOrder/List",
        title: "缓存列表"
      }]
    }, {
      title: "CAP消息总线",
      children: [{
        path: "/Sales/MaBangVersionReplaceLog/List",
        title: 'ApiSpider'
      }, {
        path: "/Sales/MaBangVersionReplaceLog/List",
        title: 'EventHandler'
      }, {
        path: "/Sales/MaBangVersionReplaceLog/List",
        title: 'Apps'
      }]
    }, {
      title: "后台错误日志",
      children: []
    }]
  }]
};