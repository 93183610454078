import { useUserStore } from "@/storePinia/user";
import { computed } from "vue";
import { useTreeOperate } from "@/core/CommonHooks/useTreeOperate";
import { nextTick } from 'vue';
import { mapState, mapGetters } from 'vuex';
import Setting from '@/setting';
import iMenuSideItem from './menu-item';
import iMenuSideSubmenu from './submenu';
import iMenuSideCollapse from './menu-collapse';
import tTitle from '../mixins/translate-title';

// 元素是否在可视区域
function isElementInViewport(el) {
  let rect = el.getBoundingClientRect();
  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
}
const __default__ = {
  name: 'iMenuSide',
  mixins: [tTitle],
  components: {
    iMenuSideItem,
    iMenuSideSubmenu,
    iMenuSideCollapse
  },
  props: {
    hideLogo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('admin/layout', ['siderTheme', 'menuAccordion', 'menuCollapse']),
    ...mapState('admin/menu', ['activePath', 'openNames']),
    ...mapGetters('admin/menu', ['filterSider'])
  },
  watch: {
    '$route': {
      handler() {
        this.handleUpdateMenuState();
      },
      immediate: true
    },
    // 在展开/收起侧边菜单栏时，更新一次 menu 的状态
    menuCollapse() {
      this.handleUpdateMenuState();
    }
  },
  methods: {
    handleUpdateMenuState() {
      nextTick(() => {
        if (this.$refs.menu) {
          this.$refs.menu.updateActiveName();
          if (this.menuAccordion) this.$refs.menu.updateOpened();
          // 聚焦当前项
          nextTick(() => {
            const $activeMenu = document.getElementsByClassName('ivu-menu-item ivu-menu-item-active ivu-menu-item-selected');
            if ($activeMenu && $activeMenu.length && !isElementInViewport($activeMenu[0])) {
              const activeMenuTop = $activeMenu[0].getBoundingClientRect().top - Setting.headerHeight;
              const $menu = this.$refs.menu.$el;
              setTimeout(() => {
                this.$ScrollTop($menu, {
                  to: activeMenuTop,
                  time: 0
                });
              }, 300);
            }
          });
        }
      });
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props, {
    expose: __expose
  }) {
    __expose();
    const userStore = useUserStore();
    const dfsTransFn = (tree, func, childrenKey = 'Childrens', level = 0, parentItem = {}) => {
      if (!Array.isArray(tree)) return [];
      level++;
      for (let i = 0; i < tree.length; i++) {
        var _tree$i;
        let node = tree[i];
        func(node, parentItem);
        if (tree[i].FType === 2) {
          tree.splice(i, 1);
          i--;
        }
        if ((_tree$i = tree[i]) !== null && _tree$i !== void 0 && _tree$i.FIsHidden) {
          tree.splice(i, 1);
          i--;
        }

        // 如果子树存在，递归调用
        if (node && node[childrenKey] && node[childrenKey].length) {
          dfsTransFn(node[childrenKey], func, childrenKey, level, node);
        }
      }
      return tree;
    };
    const filterMenu = computed(() => {
      let arr = JSON.parse(JSON.stringify(dfsTransFn(userStore.menuList, () => {})));
      return arr;
    });
    const __returned__ = {
      isElementInViewport,
      userStore,
      dfsTransFn,
      filterMenu,
      nextTick,
      get mapState() {
        return mapState;
      },
      get mapGetters() {
        return mapGetters;
      },
      get Setting() {
        return Setting;
      },
      get iMenuSideItem() {
        return iMenuSideItem;
      },
      get iMenuSideSubmenu() {
        return iMenuSideSubmenu;
      },
      get iMenuSideCollapse() {
        return iMenuSideCollapse;
      },
      get tTitle() {
        return tTitle;
      },
      get useUserStore() {
        return useUserStore;
      },
      computed,
      get useTreeOperate() {
        return useTreeOperate;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});