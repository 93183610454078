/**
 * 拼接列表过滤规则
 * @returns 
 */
export function JointListRules(data, data2) {
  let result = [];
  for (let item in data) {
    // 过滤空值
    if (data[item] === undefined || data[item] === '' || data[item] === -1 || data[item].value === null || data[item].value === undefined) continue;

    // 压入过滤规则
    result.push({
      "Key": data[item].field,
      "Value": data[item].value,
      "Operator": data[item].operators,
      "LogicalOperator": "and"
    });
  }
  return result;
}
;