import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "i-layout-header-trigger i-layout-header-trigger-min i-layout-header-trigger-in i-layout-header-trigger-no-height"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i_menu_head_title = _resolveComponent("i-menu-head-title");
  const _component_MenuItem = _resolveComponent("MenuItem");
  const _component_Submenu = _resolveComponent("Submenu");
  const _component_Menu = _resolveComponent("Menu");
  const _component_Icon = _resolveComponent("Icon");
  const _component_DropdownItem = _resolveComponent("DropdownItem");
  const _component_i_link = _resolveComponent("i-link");
  const _component_DropdownMenu = _resolveComponent("DropdownMenu");
  const _component_Dropdown = _resolveComponent("Dropdown");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["i-layout-menu-head", {
      'i-layout-menu-head-mobile': _ctx.isMobile
    }])
  }, [!_ctx.isMobile && !$data.isMenuLimit ? (_openBlock(), _createBlock(_component_Menu, {
    key: 0,
    mode: "horizontal",
    "active-name": _ctx.headerName,
    ref: "menu"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterHeader, (item, index) => {
      return _openBlock(), _createElementBlock(_Fragment, {
        key: item.path
      }, [item.children && item.children.length ? (_openBlock(), _createBlock(_component_Submenu, {
        name: item.name,
        key: index
      }, {
        title: _withCtx(() => [_createVNode(_component_i_menu_head_title, {
          item: item,
          badge: ""
        }, null, 8 /* PROPS */, ["item"])]),
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, subitem => {
          return _openBlock(), _createBlock(_component_MenuItem, {
            to: subitem.path,
            replace: subitem.replace,
            target: subitem.target,
            name: item.name,
            key: subitem.path,
            onClick: $event => _ctx.handleClick(subitem.path, 'header')
          }, {
            default: _withCtx(() => [_createVNode(_component_i_menu_head_title, {
              item: subitem,
              badge: ""
            }, null, 8 /* PROPS */, ["item"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "replace", "target", "name", "onClick"]);
        }), 128 /* KEYED_FRAGMENT */))]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name"])) : (_openBlock(), _createBlock(_component_MenuItem, {
        to: item.path,
        replace: item.replace,
        target: item.target,
        name: item.name,
        key: item.path,
        onClick: $event => _ctx.handleClick(item.path, 'header')
      }, {
        default: _withCtx(() => [_createVNode(_component_i_menu_head_title, {
          item: item,
          badge: ""
        }, null, 8 /* PROPS */, ["item"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "replace", "target", "name", "onClick"]))], 64 /* STABLE_FRAGMENT */);
    }), 128 /* KEYED_FRAGMENT */))]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["active-name"])) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Dropdown, {
    trigger: "click",
    class: _normalizeClass({
      'i-layout-menu-head-mobile-drop': _ctx.isMobile
    })
  }, {
    list: _withCtx(() => [_createVNode(_component_DropdownMenu, null, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterHeader, (item, index) => {
        return _openBlock(), _createElementBlock(_Fragment, null, [item.children && item.children.length ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [(_openBlock(), _createBlock(_component_DropdownItem, {
          class: "i-layout-menu-head-mobile-subtitle",
          disabled: "",
          key: index
        }, {
          default: _withCtx(() => [_createVNode(_component_i_menu_head_title, {
            item: item,
            badge: ""
          }, null, 8 /* PROPS */, ["item"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, subitem => {
          return _openBlock(), _createBlock(_component_i_link, {
            to: subitem.path,
            replace: subitem.replace,
            target: subitem.target,
            key: subitem.path,
            onClick: $event => _ctx.handleClick(subitem.path, 'header')
          }, {
            default: _withCtx(() => [_createVNode(_component_DropdownItem, null, {
              default: _withCtx(() => [_createVNode(_component_i_menu_head_title, {
                item: subitem,
                badge: ""
              }, null, 8 /* PROPS */, ["item"])]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "replace", "target", "onClick"]);
        }), 128 /* KEYED_FRAGMENT */))], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createBlock(_component_i_link, {
          to: item.path,
          replace: item.replace,
          target: item.target,
          key: item.path,
          onClick: $event => _ctx.handleClick(item.path, 'header')
        }, {
          default: _withCtx(() => [_createVNode(_component_DropdownItem, null, {
            default: _withCtx(() => [_createVNode(_component_i_menu_head_title, {
              item: item,
              badge: ""
            }, null, 8 /* PROPS */, ["item"])]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "replace", "target", "onClick"]))], 64 /* STABLE_FRAGMENT */);
      }), 256 /* UNKEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    })]),
    default: _withCtx(() => [_createVNode(_component_Icon, {
      type: "ios-apps"
    })]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"])]))], 2 /* CLASS */);
}