import { createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_button = _resolveComponent("a-button");
  const _component_a_upload = _resolveComponent("a-upload");
  const _component_a_form_item = _resolveComponent("a-form-item");
  const _component_a_form = _resolveComponent("a-form");
  const _component_a_progress = _resolveComponent("a-progress");
  return _openBlock(), _createElementBlock("div", null, [_createVNode($setup["Drawer"], _mergeProps(_ctx.$attrs, {
    onOnSave: $setup.onSave,
    ref: "upDrawerRef",
    onOnCancel: $setup.onCancel
  }), {
    default: _withCtx(() => [$setup.type === 1 ? (_openBlock(), _createBlock(_component_a_form, {
      key: 0,
      model: $setup.form,
      rules: _ctx.rules
    }, {
      default: _withCtx(() => [_createVNode(_component_a_form_item, {
        label: "选择文件"
      }, {
        default: _withCtx(() => [_createVNode(_component_a_upload, {
          "file-list": $setup.form.fileList,
          "before-upload": $setup.beforeUpload,
          onRemove: $setup.handleRemove
        }, {
          default: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_a_button, null, {
            default: _withCtx(() => [_createVNode($setup["UploadOutlined"]), _cache[0] || (_cache[0] = _createTextVNode(" 上传 "))]),
            _: 1 /* STABLE */
          })])]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["file-list"])]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["model", "rules"])) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("div", {
      style: {
        "color": "red"
      }
    }, "文件上传中，请不要刷新浏览器", -1 /* HOISTED */)), _createVNode(_component_a_progress, {
      percent: $setup.progressCallbackValue.percentageValue,
      status: "active"
    }, null, 8 /* PROPS */, ["percent"]), _createElementVNode("div", null, "预计剩余时间：" + _toDisplayString($setup.progressCallbackValue.estimatedRemainingTime), 1 /* TEXT */), _createElementVNode("span", null, "当前上传速度：（" + _toDisplayString($setup.progressCallbackValue.averageRate) + "）", 1 /* TEXT */), _createElementVNode("span", null, "已上传: " + _toDisplayString($setup.progressCallbackValue.transferredAmount), 1 /* TEXT */), _createElementVNode("span", null, "文件总大小：" + _toDisplayString($setup.progressCallbackValue.totalAmount), 1 /* TEXT */)]))]),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */)]);
}