import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "i-layout-tabs-main"
};
const _hoisted_2 = {
  class: "i-layout-tabs-close-main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TabPane = _resolveComponent("TabPane");
  const _component_Tabs = _resolveComponent("Tabs");
  const _component_Icon = _resolveComponent("Icon");
  const _component_DropdownItem = _resolveComponent("DropdownItem");
  const _component_DropdownMenu = _resolveComponent("DropdownMenu");
  const _component_Dropdown = _resolveComponent("Dropdown");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["i-layout-tabs", $options.classes]),
    style: _normalizeStyle($options.styles)
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_Tabs, {
    type: "card",
    "model-value": _ctx.current,
    animated: false,
    closable: "",
    draggable: _ctx.tabsOrder,
    onOnClick: $options.handleClickTab,
    onOnTabRemove: $options.handleClickClose,
    onOnDragDrop: $options.handleDragDrop
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.opened, (page, index) => {
      return _openBlock(), _createBlock(_component_TabPane, {
        key: index,
        label: h => $options.tabLabel(h, page),
        name: page.fullPath,
        closable: page.meta && page.meta.closable
      }, null, 8 /* PROPS */, ["label", "name", "closable"]);
    }), 128 /* KEYED_FRAGMENT */))]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model-value", "draggable", "onOnClick", "onOnTabRemove", "onOnDragDrop"]), _createVNode(_component_Dropdown, {
    class: "i-layout-tabs-close",
    onOnClick: $options.handleClose
  }, {
    list: _withCtx(() => [_createVNode(_component_DropdownMenu, null, {
      default: _withCtx(() => [_createVNode(_component_DropdownItem, {
        name: "left"
      }, {
        default: _withCtx(() => [_createVNode(_component_Icon, {
          type: "md-arrow-back"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$t('basicLayout.tabs.left')), 1 /* TEXT */)]),
        _: 1 /* STABLE */
      }), _createVNode(_component_DropdownItem, {
        name: "right"
      }, {
        default: _withCtx(() => [_createVNode(_component_Icon, {
          type: "md-arrow-forward"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$t('basicLayout.tabs.right')), 1 /* TEXT */)]),
        _: 1 /* STABLE */
      }), _createVNode(_component_DropdownItem, {
        name: "other"
      }, {
        default: _withCtx(() => [_createVNode(_component_Icon, {
          type: "md-close"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$t('basicLayout.tabs.other')), 1 /* TEXT */)]),
        _: 1 /* STABLE */
      }), _createVNode(_component_DropdownItem, {
        name: "all"
      }, {
        default: _withCtx(() => [_createVNode(_component_Icon, {
          type: "md-close-circle"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$t('basicLayout.tabs.all')), 1 /* TEXT */)]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    })]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_Icon, {
      type: "ios-arrow-down"
    })])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onOnClick"])])], 6 /* CLASS, STYLE */);
}