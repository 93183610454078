import request from '@/plugins/request';
import qs from 'qs';
import { Message, Spin } from 'view-ui-plus';

/**
 * 详情数据
 */
export function Get(url, params) {
  return request({
    method: 'Get',
    url,
    params
  });
}

/**
 * 修改数据
 */
export function Post(url, data = {}, params = {}) {
  return request({
    url: url,
    method: 'Post',
    data,
    params
  });
}
export function PostBlob(url, data) {
  return request({
    url: url,
    method: 'post',
    responseType: 'blob',
    headers: {
      responseType: 'blob'
    },
    data
  });
}

/**
 * 修改数据
 */
export function Put(url, data) {
  return request({
    url: url,
    method: 'Put',
    data
  });
}

/**
 * 删除数据
 */
export function Delete(url, params) {
  return request({
    method: 'Delete',
    url,
    params
  });
}

/**
 * 修改数据
 */
export function UploadFile(url, file, fileKey = 'file') {
  const data = new FormData();
  data.append(fileKey, file);
  return request({
    url: url,
    method: 'Post',
    data,
    headers: {
      'content-type': 'multipart/form-data'
    }
  });
}